import React, { Component } from 'react';
import { authContext } from '../../adalConfig';
import IdleTimer, { IdleTimerProps } from 'react-idle-timer';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import ModalPreset from '../common/ModalPreset';

export default class Timeout extends Component {
    idleTimer: IdleTimer | null;
    modalTimeout: number;
    timeout: number;
    state: {
        modalRemaining: number;
        isIdle: boolean;
        showModal: boolean;
        isTimedOut: boolean;
    };

    constructor(props: IdleTimerProps) {
        super(props);
        this.modalTimeout = 60000;
        this.timeout = 540000;
        this.idleTimer = null;
        this.state = {
            modalRemaining: this.modalTimeout,
            isIdle: false,
            showModal: false,
            isTimedOut: false,
        };
        // Bind event handlers and methods
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    logout = () => {
        authContext.logOut();
    };

    decrementTimeRemaining = () => {
        if (this.state.modalRemaining > 0) {
            this.setState({
                modalRemaining: this.state.modalRemaining - 1000,
            });
        } else {
            this.logout();
        }
    };

    msToSeconds(duration: number) {
        const seconds = Math.floor(duration / 1000);
        return seconds;
    }

    componentDidMount() {
        setInterval(() => {
            if (this.state.isTimedOut) {
                this.decrementTimeRemaining();
            }
        }, 1000);
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    timeout={this.timeout}
                />
                <ModalPreset show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header className="font-weight-bold">You have been idle</Modal.Header>
                    <ModalBody>
                        <p>
                            Your session is idle and you will be logged out in{' '}
                            {this.msToSeconds(this.state.modalRemaining)} seconds.
                        </p>
                    </ModalBody>
                    <Modal.Footer>
                        <Button onClick={() => this.handleClose()}>Stay</Button>
                        <Button variant="danger" onClick={() => this.handleLogout()}>
                            Logout
                        </Button>
                    </Modal.Footer>
                </ModalPreset>
            </div>
        );
    }

    handleOnActive() {
        this.setState({ isIdle: false });
    }

    handleOnIdle() {
        this.setState({
            showModal: true,
            isTimedOut: true,
        });
    }

    handleClose() {
        this.setState({
            showModal: false,
            isTimedOut: false,
            modalRemaining: this.modalTimeout,
        });
        if (this.idleTimer) this.idleTimer.reset();
    }

    handleLogout() {
        this.setState({ showModal: false });
        this.logout();
    }
}
