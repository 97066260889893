import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useState, useRef, useEffect } from 'react';
import { Button, Col, Container, Form, FormControl, Row } from 'react-bootstrap';
import AppContext from '../../AppContext';
import Loading from '../common/Loading';
import TooltipPreset from '../common/TooltipPreset';
import { IExtendsInformedConsentModal, informedConsentModalActionCreators } from './informedConsentModalReducer';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import SubmissionsAPI from '../../api/SubmissionsAPI';

const { resetModalAction } = informedConsentModalActionCreators;

interface IProps extends IExtendsInformedConsentModal {
    currentTimeframe: WindowSimple;
    getOrganizationSubmissions(organizationId: number): void;
}
export default function InformedConsentSubmit({ dispatchModal, modal, getOrganizationSubmissions }: IProps) {
    const { user, resetAlerts } = useContext(AppContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [uploading, setUploading] = useState(false);

    const submissionsApi = useRef(new SubmissionsAPI());
    const createSubmitFile = useCallback(() => {
        setSubmitted(true);
        if (firstName !== '' && lastName !== '') {
            setUploading(true);
            submissionsApi.current
                .updateInformedConsentSubmission(
                    Object.assign({}, modal.submission, {
                        firstName,
                        lastName,
                        submissionComplete: true,
                        submissionFiles: [
                            {
                                submissionId: modal.submission?.submissionId,
                                originalFileName: modal.listName,
                                blobFileName: modal.blobListName,
                                uploadSucceed: true,
                                dateUploaded: modal.submission?.created,
                            },
                            {
                                submissionId: modal.submission?.submissionId,
                                originalFileName: modal.reportName,
                                blobFileName: modal.blobReportName,
                                uploadSucceed: true,
                                dateUploaded: modal.submission?.created,
                            },
                        ],
                    }),
                )
                .then(() => {
                    dispatchModal(resetModalAction({ section: 5 }));
                    getOrganizationSubmissions(user.userData!.organizationSimple!.organizationId!);
                })
                .catch(() => setUploading(false));
        }
    }, [dispatchModal, firstName, getOrganizationSubmissions, lastName, modal, user.userData]);

    useEffect(() => {
        resetAlerts();
    }, [resetAlerts]);

    if (uploading) return <Loading />;

    return (
        <Container>
            <Row className="d-flex flex-column text-center align-items-center">
                <h4>File Approved for Submission</h4>
                <p>
                    Please enter your name and submit your Informed Consent List and Signed Releases
                    <TooltipPreset text="By entering your name, you are acknowledging that you are the designated representative for your organization to submit the Informed Consent List and Signed Forms.">
                        <FontAwesomeIcon className="ml-1" icon={faQuestionCircle} />
                    </TooltipPreset>
                </p>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <FormControl
                                    required
                                    className={submitted && !firstName.length ? 'alert-danger' : ''}
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFirstName(e.currentTarget.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <FormControl
                                    required
                                    className={submitted && !lastName.length ? 'alert-danger' : ''}
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setLastName(e.currentTarget.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a last name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center align-items-baseline">
                        <Button className="mt-3" onClick={createSubmitFile}>
                            Submit
                        </Button>
                        <TooltipPreset text="Your Informed Consent List and Signed Forms have now been submitted.">
                            <FontAwesomeIcon className="ml-1" icon={faQuestionCircle} />
                        </TooltipPreset>
                    </div>
                </Form>
            </Row>
        </Container>
    );
}
