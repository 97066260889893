import React from 'react';
import { Container, Button } from 'react-bootstrap';

interface IProps {
    onClose(): void;
    description: string;
}
export default function SectionSuccess({ onClose, description }: IProps) {
    return (
        <Container className="d-flex justify-content-center flex-column">
            <h3 className="text-center mt-4 mb-3">
                <b>Success!</b>
            </h3>
            <br />
            <p className="text-center" children={description} />
            <br />
            <div className="pb-2 text-center">
                <Button onClick={onClose}>Close</Button>
            </div>
        </Container>
    );
}
