import React, { useEffect, useMemo, useReducer, useState, useCallback, useRef, useContext } from 'react';
import { Alert, Modal, ModalBody } from 'react-bootstrap';
import ModalPreset from '../common/ModalPreset';
import ProgressBar from '../ProgressBar/ProgressBar';
import hashingModalReducer, {
    getDefaultInformedConsentModalState,
    informedConsentModalActionCreators,
} from './informedConsentModalReducer';
import InformedConsentFormSelect from './InformedConsentFormSelect';
import InformedConsentListSelect from './InformedConsentListSelect';
import InformedConsentUpload from './InformedConsentUpload';
import SectionError from '../SectionError';
import SectionFileScan from '../SectionFileScan';
import SectionSuccess from '../SectionSuccess';
import InformedConsentSubmit from './InformedConsentSubmit';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import FileUploadAPI, { SasUri } from '../../api/FileUploadAPI';
import AppContext from '../../AppContext';

const { resetModalAction, setSectionAction } = informedConsentModalActionCreators;

interface IProps {
    show: boolean;
    onClose(): void;
    currentTimeframe: WindowSimple;
    getOrganizationSubmissions(organizationId: number): void;
}
export default function InformedConsentModal({ show, onClose, currentTimeframe, getOrganizationSubmissions }: IProps) {
    const { alertAdd, alerts, alertDismiss } = useContext(AppContext);
    const [modal, dispatchModal] = useReducer(hashingModalReducer, getDefaultInformedConsentModalState());
    const fileUploadApi = useRef(new FileUploadAPI());
    const [sasLoaded, setSasLoaded] = useState<boolean>();
    const [sas, setSas] = useState<SasUri>();
    const loadSas = useCallback(() => {
        setSasLoaded(false);
        fileUploadApi.current
            .getSasToken()
            .then(setSas)
            .catch((error) => {
                alertAdd({
                    variant: 'danger',
                    text: error,
                });
            })
            .finally(() => setSasLoaded(true));
    }, [alertAdd]);
    useEffect(() => {
        if (!show && sas) {
            setSas(undefined);
            setSasLoaded(undefined);
            dispatchModal(resetModalAction());
            return;
        } else if (show && sasLoaded === undefined) {
            loadSas();
        }
        // console.log("SAS", show, sasLoaded, sas)
    }, [loadSas, sas, sasLoaded, show]);

    const activeSegment = useMemo(() => {
        if (modal.section >= 5) {
            return 4;
        }
        if (modal.section === 4) {
            return modal.section - 1;
        }
        if (modal.section >= 3) {
        }
        return modal.section;
    }, [modal.section]);

    const renderSwitch = useMemo(() => {
        switch (modal.section) {
            case 0:
                return (
                    <InformedConsentFormSelect
                        modal={modal}
                        dispatchModal={dispatchModal}
                        currentTimeframe={currentTimeframe}
                    />
                );
            case 1:
                return (
                    <InformedConsentListSelect
                        modal={modal}
                        dispatchModal={dispatchModal}
                        currentTimeframe={currentTimeframe}
                    />
                );
            case 2:
                return (
                    <InformedConsentUpload
                        modal={modal}
                        dispatchModal={dispatchModal}
                        currentTimeframe={currentTimeframe}
                        sas={sas}
                    />
                );
            case 3:
                return <SectionFileScan modal={modal} goBack={() => dispatchModal(setSectionAction(0))} />;
            case 4:
                return (
                    <InformedConsentSubmit
                        dispatchModal={dispatchModal}
                        modal={modal}
                        currentTimeframe={currentTimeframe}
                        getOrganizationSubmissions={getOrganizationSubmissions}
                    />
                );
            case 5:
                return (
                    <SectionSuccess
                        onClose={onClose}
                        description="Your Informed Consent List and Signed Release Forms have been securely submitted!"
                    />
                );
            case 6:
                return (
                    <SectionError
                        onClose={onClose}
                        description="Your Informed Consent List and Signed Release Forms appear to be valid but something else went wrong. If you continue to see this message please contact a site administrator."
                        tooltip="Something has gone wrong in your upload. Please review your submissions and try again. If you continue to see this message please contact a site administrator"
                    />
                );
            default:
                return <div></div>;
        }
    }, [currentTimeframe, getOrganizationSubmissions, modal, onClose, sas]);

    return (
        <ModalPreset size="lg" show={show} onHide={onClose}>
            <Modal.Header closeButton className="border-bottom-0">
                <ProgressBar
                    segments={5}
                    activeSegment={activeSegment}
                    // onClickSegment={i => dispatchModal(setSectionAction(i))}
                />
            </Modal.Header>
            <ModalBody className="hashing-modal__body">
                {alerts &&
                    alerts.map((alert: any, i: number) => {
                        if (alert) {
                            return (
                                <Alert variant={alert.variant} key={i} onClose={() => alertDismiss(i)} dismissible>
                                    {alert.text}
                                </Alert>
                            );
                        }
                        return null;
                    })}
                <h4 className="text-center">Informed Consent List & Signed Consent Forms Upload</h4>
                {renderSwitch}
            </ModalBody>
        </ModalPreset>
    );
}
