import React from 'react';
import { OrganizationSimple } from '../../../api/OrganizationsAPI';
import { Submission } from '../../../api/SubmissionsAPI';
import SubmissionsTable from './SubmissionsTable';
import { WindowSimple } from '../Windows/windowsLib';

interface SubmissionProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: (error: any) => void;
    getAllSubmissions: () => void;
    submissions: Submission[] | null;
}
interface SubmissionState {
    uniqueOrganizations: OrganizationSimple[];
    uniqueWindows: WindowSimple[];
    uniqueYears: number[];
}

export default class Submissions extends React.Component<SubmissionProps, SubmissionState> {
    state: SubmissionState = {
        uniqueOrganizations: [],
        uniqueWindows: [],
        uniqueYears: [],
    };

    componentDidMount() {
        !this.props.submissions && this.props.getAllSubmissions();
        this.collectFilterValues();
    }

    componentDidUpdate(prevProps: SubmissionProps) {
        if (this.props.submissions !== prevProps.submissions) {
            this.collectFilterValues();
        }
    }

    collectFilterValues() {
        let uniqueOrganizations: OrganizationSimple[] = [];
        let uniqueWindows: WindowSimple[] = [];
        let uniqueYears: number[] = [];
        if (this.props.submissions !== null) {
            for (let submission of this.props.submissions) {
                const organization = submission.user.organizationSimple;
                const window = submission.window;
                const year = new Date(submission.created).getFullYear();
                if (
                    organization &&
                    !uniqueOrganizations.some((o) => o.organizationId === organization?.organizationId)
                ) {
                    uniqueOrganizations.push(organization!);
                }
                if (window && !uniqueWindows.some((w) => w.windowId === window.windowId)) {
                    uniqueWindows.push(window);
                }
                if (year && !uniqueYears.some((y) => y === year)) {
                    uniqueYears.push(year);
                }
            }
            this.setState({
                uniqueWindows,
                uniqueOrganizations,
                uniqueYears: uniqueYears.sort((a, b) => b - a),
            });
        }
    }

    render() {
        return (
            <div>
                <SubmissionsTable
                    submissions={this.props.submissions}
                    uniqueOrganizations={this.state.uniqueOrganizations}
                    uniqueWindows={this.state.uniqueWindows}
                    uniqueYears={this.state.uniqueYears}
                />
            </div>
        );
    }
}
