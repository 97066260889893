import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Modal,
    Alert,
    Button,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    ModalProps,
    Spinner,
    Form,
} from 'react-bootstrap';
import UsersAPI from '../../../api/UsersAPI';
import AppContext from '../../../AppContext';
import { components } from '../../../types/openApi';
import ModalPreset from '../../common/ModalPreset';
import { UserForm } from './usersLib';

const defaultUserForm: UserForm = {
    userId: 0,
    organizationId: -1,
    email: '',
    firstName: '',
    lastName: '',
    organizationSimple: {
        name: '',
    },
};
function getDefaultUserForm(form?: Partial<UserForm> | undefined | null) {
    return Object.assign({}, defaultUserForm, form);
}

interface IProps {
    onHide: NonNullable<ModalProps['onHide']>;
    /**
     * null is create state
     * undefined is hide state
     * otherwise edit state
     */
    user: components['schemas']['UserSimple'] | null | undefined;
    onUpsertUser(): void;
    organizations: components['schemas']['OrganizationSimple'][] | null;
}
export default function UpsertUserModal({ user, onUpsertUser, onHide, organizations }: IProps) {
    const [userForm, setUserForm] = useState<UserForm>(getDefaultUserForm);
    const [show, setShow] = useState(false);
    const editing = userForm.organizationId !== -1;
    useEffect(() => {
        if (user !== undefined) {
            setShow(true);
            setUserForm(getDefaultUserForm(user as UserForm | null));
        } else {
            setShow(false);
        }
    }, [user]);
    const { handleError, alerts, alertDismiss } = useContext(AppContext);
    const usersApi = useRef(new UsersAPI());

    const [submitting, setSubmitting] = useState(false);
    const submitHandler = useCallback(
        (event: React.FormEvent<HTMLFormElement> | undefined) => {
            event?.preventDefault();
            setSubmitting(true);
            const upsert = editing
                ? usersApi.current.updateUser(userForm)
                : usersApi.current.createUser(
                      Object.assign({}, userForm, { organizationId: userForm.organizationSimple.organizationId }),
                  );
            upsert
                .then(() => {
                    onUpsertUser();
                    onHide();
                })
                .catch(handleError)
                .finally(() => setSubmitting(false));
        },
        [editing, handleError, onHide, onUpsertUser, userForm],
    );

    const submitLabel = useMemo(() => {
        if (submitting) return <Spinner animation="border" size="sm" />;
        return editing ? 'Update' : 'Add';
    }, [editing, submitting]);

    return (
        <ModalPreset show={show} onHide={onHide} onExited={() => setUserForm(getDefaultUserForm())}>
            <Form onSubmit={submitHandler}>
                <Modal.Header className="font-weight-bold">{editing ? 'Edit' : 'Add'} User</Modal.Header>
                <Modal.Body>
                    {alerts?.map((alert, i) => {
                        if (alert) {
                            return (
                                <Alert variant="danger" key={i} onClose={() => alertDismiss(i)} dismissible>
                                    {alert.text}
                                </Alert>
                            );
                        }
                        return null;
                    })}
                    {editing && (
                        <FormGroup className="w-100">
                            <FormLabel>Organization:</FormLabel>
                            <FormControl type="text" value={userForm.organizationSimple.name} disabled />
                        </FormGroup>
                    )}
                    <InputGroup>
                        {editing || (
                            <FormGroup className="w-250">
                                <FormControl
                                    as="select"
                                    className="mb-3 user-modal"
                                    required
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        const organizationId = event.currentTarget.value
                                            ? Number(event.currentTarget.value)
                                            : -1;
                                        const name = organizationId
                                            ? event.target.options[event.target.selectedIndex].text
                                            : '';

                                        setUserForm((_) => ({
                                            ..._,
                                            organizationSimple: {
                                                name,
                                                organizationId,
                                            },
                                        }));
                                    }}
                                >
                                    <option value="">Organization</option>
                                    {organizations?.map((organization) => (
                                        <option key={organization.organizationId} value={organization.organizationId}>
                                            {organization.name}
                                        </option>
                                    ))}
                                </FormControl>
                            </FormGroup>
                        )}

                        <FormGroup className="w-100">
                            <FormLabel>Email:</FormLabel>
                            <FormControl
                                type="text"
                                disabled={editing}
                                required
                                value={userForm.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const email = e.currentTarget.value;
                                    setUserForm((_) => ({ ..._, email }));
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="w-100">
                            <FormLabel>First Name:</FormLabel>
                            <FormControl
                                type="text"
                                required
                                value={userForm.firstName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const firstName = e.currentTarget.value;
                                    setUserForm((_) => ({ ..._, firstName }));
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="w-100">
                            <FormLabel>Last Name:</FormLabel>
                            <FormControl
                                type="text"
                                required
                                value={userForm.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const lastName = e.currentTarget.value;
                                    setUserForm((_) => ({ ..._, lastName }));
                                }}
                            />
                        </FormGroup>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide} disabled={submitting} children="Cancel" />
                    <Button type="submit" disabled={submitting} children={submitLabel} />
                </Modal.Footer>
            </Form>
        </ModalPreset>
    );
}
