import { components } from '../types/openApi';
import APILayer from './APILayer';

export type OrganizationSimple = components['schemas']['OrganizationSimple'];
export default class OrganizationsAPI {
    APILayer = new APILayer();

    async getAllOrganizations(): Promise<OrganizationSimple[]> {
        return this.APILayer.get('/api/organizations/GetAll').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve organizations.');
            }
            return response.json();
        });
    }

    async getActiveOrganizations(): Promise<OrganizationSimple[]> {
        return this.APILayer.get('/api/organizations/GetActive').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve active organizations.');
            }
            return response.json();
        });
    }

    async createOrganization(newOrgName: string): Promise<boolean> {
        const body = {
            name: newOrgName,
        };

        return this.APILayer.post('/api/organizations/Add', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to create organization.');
            }
            return true;
        });
    }

    async deleteOrganization(id: number): Promise<boolean> {
        const body = {
            organizationId: id,
        };

        return this.APILayer.post('/api/organizations/Delete', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to remove organization.');
            }
            return true;
        });
    }

    async updateOrganization(
        OrganizationId: NonNullable<OrganizationSimple>['organizationId'],
        Name: NonNullable<OrganizationSimple>['name'],
    ): Promise<boolean> {
        const body = {
            OrganizationId,
            Name,
            IsActive: true,
        };

        return this.APILayer.post('/api/organizations/Edit', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to update organization.');
            }
            return true;
        });
    }
}
