import React, { ContextType } from 'react';
import { Container, Row, Col, Button, Table, Modal, ModalBody, InputGroup, FormControl, Alert } from 'react-bootstrap';
import ModalPreset from '../../common/ModalPreset';
import Loading from '../../common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { OrganizationSimple } from '../../../api/OrganizationsAPI';

interface OrganizationsProps {
    getAllOrganizations: () => void;
    createOrganization: (newOrgName: string) => Promise<boolean>;
    deleteOrganization: (id: number) => void;
    updateOrganization: (organization: OrganizationSimple) => void;
    organizations: OrganizationSimple[] | null;
}

interface OrganizationsState {
    modal: string;
    alertMessage: string;
    newOrganization: any;
    editOrganization: any;
}

export default class Organizations extends React.Component<OrganizationsProps, OrganizationsState> {
    state = {
        modal: '',
        alertMessage: '',
        newOrganization: '',
        editOrganization: {
            id: 0,
            name: '',
        },
    };

    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;

    componentDidMount() {
        !this.props.organizations && this.props.getAllOrganizations();
    }

    createOrganization = () => {
        if (this.state.newOrganization !== '') {
            const one = new Promise<boolean>((resolve, reject) => {
                resolve(this.props.createOrganization(this.state.newOrganization));
            });
            one.then((result) => {
                if (!result) {
                    this.setModalShow('');
                    this.context.alertDismiss(0);
                }
            });
        }
    };

    deleteOrganization = () => {
        this.setModalShow('');
        this.props.deleteOrganization(this.state.editOrganization.id);
    };

    updateOrganization = () => {
        this.setModalShow('');
        this.props.updateOrganization({
            ...this.state.editOrganization,
            organizationId: this.state.editOrganization.id,
        });
    };

    setModalShow = (modalState: string) => {
        this.setState({ modal: modalState });
    };

    createTableData = (organization: any, i: number) => (
        <tr key={i}>
            <td className="align-middle pl-3">{organization.name}</td>
            <td className="text-center">
                <Button
                    variant="info"
                    className="mr-3 mt-2 mb-2"
                    onClick={() => {
                        this.setModalShow('edit_org');
                        this.setState({
                            editOrganization: {
                                ...this.state.editOrganization,
                                id: organization.organizationId,
                                name: organization.name,
                                isActive: organization.isActive,
                            },
                        });
                    }}
                >
                    {' '}
                    Edit
                </Button>
                <Button
                    variant="warning"
                    className="mt-2 mb-2"
                    onClick={() => {
                        this.setModalShow('archive_org');
                        this.setState({
                            editOrganization: {
                                ...this.state.editOrganization,
                                id: organization.organizationId,
                            },
                        });
                    }}
                >
                    {' '}
                    Archive
                </Button>
            </td>
        </tr>
    );

    render() {
        return (
            <div>
                <Container>
                    <Row></Row>
                    <Row className="mt-2">
                        <Col md={4}>
                            <h2>Organizations</h2>
                        </Col>
                        <Col className="text-right" md={8}>
                            <Button onClick={() => this.setModalShow('create_org')}>Add New Organization</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link to="/admin/archivedorganizations">Archived Organizations</Link>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Table striped bordered size="sm">
                                <thead>
                                    <tr>
                                        <th className="org-name-table-header pt-3 pb-3 pl-3">Organization Name</th>
                                        <th className="text-center pt-3 pb-3 pr-3">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.organizations ? (
                                        this.props.organizations.map((organization: any, i: number) => {
                                            if (organization.isActive === true) {
                                                return this.createTableData(organization, i);
                                            }
                                            return null;
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <ModalPreset show={this.state.modal === 'create_org'}>
                        <Modal.Header className="font-weight-bold">Add Organization</Modal.Header>

                        <ModalBody>
                            {this.context.alerts &&
                                this.context.alerts.map((alert: any, i: number) => {
                                    if (alert) {
                                        return (
                                            <Alert
                                                variant="danger"
                                                key={i}
                                                onClose={() => this.context.alertDismiss(i)}
                                                dismissible
                                            >
                                                {alert.text}
                                            </Alert>
                                        );
                                    }
                                    return null;
                                })}
                            <p>Organization Name:</p>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    onChange={(e: any) => {
                                        this.setState({
                                            newOrganization: e.target.value,
                                        });
                                    }}
                                />
                            </InputGroup>
                        </ModalBody>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.setModalShow('');
                                    this.context.alertDismiss(0);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={() => this.createOrganization()}>Add</Button>
                        </Modal.Footer>
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'edit_org'}>
                        <Modal.Header className="font-weight-bold">Edit Organization</Modal.Header>
                        <ModalBody>
                            {this.context.alerts &&
                                this.context.alerts.map((alert: any, i: number) => {
                                    if (alert) {
                                        return (
                                            <Alert
                                                variant="danger"
                                                key={i}
                                                onClose={() => this.context.alertDismiss(i)}
                                                dismissible
                                            >
                                                {alert.text}
                                            </Alert>
                                        );
                                    }
                                    return null;
                                })}
                            <p>Organization Name:</p>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    value={this.state.editOrganization.name}
                                    onChange={(e: any) => {
                                        this.setState({
                                            editOrganization: {
                                                ...this.state.editOrganization,
                                                name: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </InputGroup>
                        </ModalBody>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.setModalShow('');
                                    this.context.alertDismiss(0);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={() => this.updateOrganization()}>Save</Button>
                        </Modal.Footer>
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'archive_org'}>
                        <Modal.Header className="font-weight-bold">
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                            Archive Organization
                        </Modal.Header>
                        <ModalBody>
                            {this.context.alerts &&
                                this.context.alerts.map((alert: any, i: number) => {
                                    if (alert) {
                                        return (
                                            <Alert
                                                variant="danger"
                                                key={i}
                                                onClose={() => this.context.alertDismiss(i)}
                                                dismissible
                                            >
                                                {alert.text}
                                            </Alert>
                                        );
                                    }
                                    return null;
                                })}
                            Are you sure you want to archive this organization? This will archive all users associated
                            with the organization and cannot be undone.
                        </ModalBody>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.setModalShow('');
                                    this.context.alertDismiss(0);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant="warning" onClick={() => this.deleteOrganization()}>
                                Archive
                            </Button>
                        </Modal.Footer>
                    </ModalPreset>
                </Container>
            </div>
        );
    }
}
