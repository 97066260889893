import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Table, Row, Button } from 'react-bootstrap';
import { InvalidFileData } from '../api/FileUploadAPI';
import TooltipPreset from './common/TooltipPreset';
import { IExtendsHashingModal } from './HashingModal/hashingModalReducer';

interface IProps {
    modal: Pick<IExtendsHashingModal['modal'], 'errorData' | 'errorMessage'>;
    goBack(): void;
}
export default function SectionFileScan({ modal: { errorData, errorMessage }, goBack }: IProps) {
    var errorCount = 0;
    errorData?.map((column: any) => {
        return (errorCount += column.rowNumbers.length);
    });
    return (
        <Container>
            <div className="text-center mb-3">
                <p>File Scan Status: {errorMessage !== '' ? errorMessage : 'Completed'}</p>
                {errorMessage === '' && (
                    <div>
                        <TooltipPreset text="The file validity scan has found errors in the file you submitted. The information below will tell you where in your file the error was detected and the type of data the system anticipated in those cells. ">
                            <FontAwesomeIcon className="file-error-icon" icon={faExclamationTriangle} />
                        </TooltipPreset>
                        <h5>{errorCount} Error(s) Found</h5>
                    </div>
                )}
            </div>
            <hr />
            {errorData?.map((column: InvalidFileData) => (
                <Container className="d-flex flex-column align-items-center">
                    <Table className="incorrect-tables mt-2" striped bordered size="sm">
                        <thead>
                            <tr>
                                <th className="text-center">Row</th>
                                <th>Column</th>
                                <th>Expected Data Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {column?.rowNumbers?.map((row: number) => {
                                return (
                                    <tr>
                                        <td className="text-center">{row}</td>
                                        <td>{column.column}</td>
                                        <td>{column.dataType}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Container>
            ))}
            <Row className="justify-content-center mt-2">
                <Button onClick={goBack}>Go Back</Button>
            </Row>
        </Container>
    );
}
