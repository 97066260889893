import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface TooltipProps {
    text: string;
}

export const TooltipPreset: React.FC<TooltipProps> = ({ children, ...rest }) => {
    return (
        <OverlayTrigger
            {...rest}
            delay={{ show: 500, hide: 0 }}
            overlay={<Tooltip id="tooltip-disabled">{rest.text}</Tooltip>}
            trigger={['hover', 'focus']}
        >
            {children as React.ReactElement}
        </OverlayTrigger>
    );
};

export default TooltipPreset;
