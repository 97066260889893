import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Alert, Button, Modal, ModalBody } from 'react-bootstrap';
import AppContext from '../../../AppContext';
import ModalPreset from '../../common/ModalPreset';

interface IProps {
    show: boolean;
    onDelete(): void;
}
export default function DeleteUserModal({ show, onDelete }: IProps) {
    const { alerts, alertDismiss } = useContext(AppContext);

    return (
        <ModalPreset show={show}>
            <Modal.Header className="font-weight-bold">
                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                Delete User
            </Modal.Header>
            <ModalBody>
                {alerts &&
                    alerts.map((alert: any, i: number) => {
                        if (alert) {
                            return (
                                <Alert variant="danger" key={i} onClose={() => alertDismiss(i)} dismissible>
                                    {alert.text}
                                </Alert>
                            );
                        }
                        return null;
                    })}
                Are you sure you want to delete this user? This action cannot be undone.
            </ModalBody>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        alertDismiss(0);
                    }}
                >
                    Cancel
                </Button>
                <Button variant="danger" onClick={onDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </ModalPreset>
    );
}
