import React from 'react';

interface IProps {
    children: React.ReactChild;
    active?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export default function ProgressItem({ active = false, children, onClick }: IProps) {
    const className = active ? 'step active' : 'step';
    return <div className={className} children={children} onClick={onClick} />;
}
