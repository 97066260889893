import React, { useContext } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import AppContext from '../../../AppContext';
import { components } from '../../../types/openApi';
import Loading from '../../common/Loading';

interface IProps {
    onEdit(user: components['schemas']['UserSimple']): void;
    onDelete(email: NonNullable<components['schemas']['UserSimple']['email']> | undefined): void;
}
export default function UsersTable({ onEdit, onDelete }: IProps) {
    const { users } = useContext(AppContext);

    return (
        <Row className="mt-3">
            <Col>
                <Table className="text-center" striped bordered size="sm">
                    <thead>
                        <tr>
                            <th className="users-table-headers">Organizations</th>
                            <th className="users-table-headers">First Name</th>
                            <th className="users-table-headers">Last Name</th>
                            <th className="users-table-headers">Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.reduce((renderList, user, index) => {
                            if (user.isActive && user.organizationSimple?.isActive) {
                                renderList.push(
                                    <tr key={index}>
                                        <td className="align-middle">{user.organizationSimple?.name}</td>
                                        <td className="align-middle">{user.firstName}</td>
                                        <td className="align-middle">{user.lastName}</td>
                                        <td className="align-middle">{user.email}</td>
                                        <td>
                                            <Button
                                                variant="info"
                                                className="mr-3 mt-2 mb-2"
                                                onClick={() => onEdit(user)}
                                                children="Edit"
                                            />
                                            <Button
                                                variant="danger"
                                                className="mt-2 mb-2"
                                                onClick={() => onDelete(user.email || undefined)}
                                                children="Delete"
                                            />
                                        </td>
                                    </tr>,
                                );
                            }
                            return renderList;
                        }, [] as JSX.Element[]) ?? (
                            <tr>
                                <td colSpan={5}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
