import React from 'react';
import { Container, Row } from 'react-bootstrap';
import formatDate from '../../lib/formatDate';
import { WindowSimple } from '../Admin/Windows/windowsLib';

interface IProps {
    window: WindowSimple;
    children: React.ReactChild;
}
export default function SectionFileSelectWrapper({
    window: { dataCollectedStart, dataCollectedEnd },
    children,
}: IProps) {
    return (
        <Container>
            <Row className="d-flex justify-content-center">
                <p className="mt-4 mb-4">
                    You should be submitting data for clients served in data period: {formatDate(dataCollectedStart)} -{' '}
                    {formatDate(dataCollectedEnd)}
                </p>
            </Row>
            {children}
            <p className="mt-4 text-center text-nowrap">
                Your files will be checked for viruses, secured, and hashed. No data is currently being transmitted.
            </p>
        </Container>
    );
}
