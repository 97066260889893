import React, { useContext } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/images/uwci_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import { logout } from '../../adalConfig';

interface NavProps {
    user: any;
    logout: () => void;
}

export default function NavigationHeader() {
    const user = useContext(AppContext).user;

    return (
        <Navbar bg="light" variant="light" expand="lg" className={user.isAdmin ? 'mb-4' : ''}>
            <Container>
                <Navbar.Brand>
                    <img src={logo} alt="UWCI" width="110" height="70" className="d-inline-block align-top" />
                    <span className="navbar-text ml-3">UWCI Impact Data Hashing Portal</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {user.isAdmin ? (
                        <Nav className="ml-auto">
                            <Nav.Link
                                className={window.location.pathname === '/admin' ? 'active-nav' : ''}
                                as={Link}
                                to="/admin"
                            >
                                Submissions
                            </Nav.Link>
                            <Nav.Link
                                className={window.location.pathname === '/admin/windows' ? 'active-nav' : ''}
                                as={Link}
                                to="/admin/windows"
                            >
                                Timeframes
                            </Nav.Link>
                            <Nav.Link
                                className={window.location.pathname === '/admin/organizations' ? 'active-nav' : ''}
                                as={Link}
                                to="/admin/organizations"
                            >
                                Organizations
                            </Nav.Link>
                            <Nav.Link
                                className={window.location.pathname === '/admin/users' ? 'active-nav' : ''}
                                as={Link}
                                to="/admin/users"
                            >
                                Users
                            </Nav.Link>
                            <Nav.Link
                                className={window.location.pathname === '/admin/admins' ? 'active-nav' : ''}
                                as={Link}
                                to="/admin/admins"
                            >
                                Admins
                            </Nav.Link>
                            <NavDropdown className="nav-dropdown-left" id="nav-user" title={ProfileIcon}>
                                <NavDropdown.Item>
                                    {user.displayName}
                                    <br />
                                    <small>{user.email}</small>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    ) : (
                        <Nav className="ml-auto">
                            <Nav.Link
                                className={window.location.pathname === '/user/templates' ? 'active-nav' : ''}
                                as={Link}
                                to="/user/templates"
                            >
                                Templates
                            </Nav.Link>
                            <Nav.Link
                                className={window.location.pathname === '/user' ? 'active-nav' : ''}
                                as={Link}
                                to="/user"
                            >
                                Dashboard
                            </Nav.Link>
                            <NavDropdown className="nav-dropdown-left" id="nav-user" title={ProfileIcon}>
                                <NavDropdown.Item>
                                    {user.displayName}
                                    <br />
                                    <small>{user.email}</small>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

const ProfileIcon = <FontAwesomeIcon icon={faUserCircle} />;
