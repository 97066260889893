import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import TooltipPreset from './common/TooltipPreset';

interface IProps {
    onClose(): void;
    description: string;
    tooltip?: string;
}
export default function SectionError({ onClose, description, tooltip = description }: IProps) {
    return (
        <Container className="d-flex justify-content-center flex-column">
            <h1 className="text-center mt-3">
                <b>Error</b>
            </h1>
            <div className="text-center mb-3">
                <TooltipPreset text={tooltip}>
                    <FontAwesomeIcon className="file-error-icon" icon={faExclamationTriangle} />
                </TooltipPreset>
            </div>
            <br />
            <p className="text-center" children={description} />
            <br />
            <div className="pb-2 text-center">
                <Button onClick={onClose}>Close</Button>
            </div>
        </Container>
    );
}
