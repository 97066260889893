import { components } from '../types/openApi';
import APILayer from './APILayer';

export type UserSimple = components['schemas']['UserSimple'];
export default class UsersAPI {
    APILayer = new APILayer();

    async getAllUsers(): Promise<UserSimple[]> {
        return this.APILayer.get('/api/users/GetAll').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve users.');
            }
            return response.json();
        });
    }

    async getUser(email: string): Promise<UserSimple> {
        return this.APILayer.get(`/api/users/Get/${email}`).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve user.');
            }
            return response.json();
        });
    }

    async createUser(
        user: Pick<Required<UserSimple>, 'firstName' | 'lastName' | 'email' | 'organizationId'>,
    ): Promise<void> {
        const body = {
            Email: user.email,
            FirstName: user.firstName,
            LastName: user.lastName,
            OrganizationId: user.organizationId,
        };

        return this.APILayer.post('/api/users/Add', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to create user.');
            }
        });
    }

    async deleteUser(Email: string) {
        const body = {
            Email,
        };

        return this.APILayer.post('/api/users/Delete', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to delete user.');
            }
        });
    }

    async updateUser(
        user: Pick<Required<UserSimple>, 'firstName' | 'lastName' | 'email' | 'organizationId'>,
    ): Promise<void> {
        const body = {
            email: user.email,
            FirstName: user.firstName,
            LastName: user.lastName,
        };

        return this.APILayer.post('/api/users/Edit', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to update user.');
            }
        });
    }
}
