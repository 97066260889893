import React, { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TooltipPreset from '../common/TooltipPreset';
import { BoxedWrapper } from '../BoxedWrapper';

import { IExtendsInformedConsentModal, informedConsentModalActionCreators } from './informedConsentModalReducer';
import InformedConsentWrapper from './InformedConsentWrapper';

const { selectReportAction } = informedConsentModalActionCreators;

export default function InformedConsentFormSelect({ dispatchModal, currentTimeframe }: IExtendsInformedConsentModal) {
    const fileUploadInput = useRef<HTMLInputElement>(null);

    return (
        <InformedConsentWrapper currentTimeframe={currentTimeframe}>
            <BoxedWrapper className="section-2">
                <>
                    <div className="mt-5">
                        <h5>
                            Upload Signed Informed Consent <b>.PDF</b> Forms
                            <TooltipPreset text="Upload a file with all of your clients served in the data period above. Make sure that your file is a .PDF file and includes all signed consent forms in one file.">
                                <FontAwesomeIcon className="mt-1 ml-2 file-upload-tooltip" icon={faQuestionCircle} />
                            </TooltipPreset>
                        </h5>
                        <Button className="select-file-btn mt-3">Select File</Button>
                    </div>
                    <Form.Control
                        type="file"
                        accept="application/pdf"
                        className="fileUpload"
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(e: any) => {
                            const files = e.target.files;
                            if (files?.length && files[0].type === 'application/pdf') {
                                dispatchModal(selectReportAction(e.target.files[0]));
                            }
                        }}
                        ref={fileUploadInput}
                    />
                </>
            </BoxedWrapper>
        </InformedConsentWrapper>
    );
}
