import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import TooltipPreset from './common/TooltipPreset';

interface IProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    label: React.ReactChild;
    tooltipText: string;
}
/**
 * Allows a Button to be centered without
 * the tooltip's width being calculated.
 * @returns JSX.Element
 */
export default function TooltipButton({ onClick, label, tooltipText }: IProps) {
    return (
        <div className="text-nowrap d-inline-block">
            <Button onClick={onClick} children={label} />
            <div className="d-inline-block no-width">
                <TooltipPreset text={tooltipText}>
                    <FontAwesomeIcon className="mt-1 ml-2" icon={faQuestionCircle} />
                </TooltipPreset>
            </div>
        </div>
    );
}
