import React from 'react';

export default class Checkbox extends React.Component<any, {}> {
    render() {
        return (
            <div className="form-check">
                <label className={this.props.error ? 'bg-danger text-white pl-1 pr-1' : 'pl-1 pr-1'}>
                    <input
                        type="checkbox"
                        name={this.props.label}
                        checked={this.props.isSelected}
                        onChange={this.props.onCheckboxChange}
                        className="form-check-input"
                    />
                    {this.props.label}
                </label>
            </div>
        );
    }
}
