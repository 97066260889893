import React from 'react';
import { Submission } from '../../api/SubmissionsAPI';
import { WindowSimple } from '../Admin/Windows/windowsLib';

interface SubmittedUser {
    firstName: string;
    lastName: string;
    rowsSubmitted: number;
    hash: string;
}
type Section = 0 | 1 | 2 | 3 | 4 | 5 | 6;
interface InformedConsentModalState {
    section: Section;
    secureData: any[];
    reportData: File | null;
    reportName: string;
    blobReportName: string;
    listData: File | null;
    listName: string;
    blobListName: string;
    errorMessage: string;
    errorData: any[];
    submission: Submission | null;
}

const defaultInformedConsentModalState: InformedConsentModalState = {
    section: 0,
    secureData: [{}],
    errorData: [],
    errorMessage: '',
    reportData: null,
    reportName: '',
    blobReportName: '',
    listData: null,
    listName: '',
    blobListName: '',
    submission: null,
};
export function getDefaultInformedConsentModalState(state: Partial<InformedConsentModalState> = {}) {
    return Object.assign({}, defaultInformedConsentModalState, state);
}

export enum InformedConsentModalActions {
    RESET_MODAL = 'RESET_MODAL',
    SELECT_REPORT = 'SELECT_REPORT',
    SELECT_LIST = 'SELECT_LIST',
    UPLOAD_ERROR = 'UPLOAD_ERROR',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
    SET_SUBMISSION = 'SET_SUBMISSION',
    SET_SECTION = 'SET_SECTION',
}

interface ResetModalAction {
    type: InformedConsentModalActions.RESET_MODAL;
    initialState: Partial<InformedConsentModalState>;
}
function resetModalAction(initialState: ResetModalAction['initialState'] = {}): ResetModalAction {
    return {
        type: InformedConsentModalActions.RESET_MODAL,
        initialState,
    };
}

interface SelectReportAction {
    type: InformedConsentModalActions.SELECT_REPORT;
    file: File;
}
function selectReportAction(file: File): SelectReportAction {
    return {
        type: InformedConsentModalActions.SELECT_REPORT,
        file,
    };
}
interface SelectListAction {
    type: InformedConsentModalActions.SELECT_LIST;
    file: File;
}
function selectListAction(file: File): SelectListAction {
    return {
        type: InformedConsentModalActions.SELECT_LIST,
        file,
    };
}

interface UploadErrorAction {
    type: InformedConsentModalActions.UPLOAD_ERROR;
    errorMessage: InformedConsentModalState['errorMessage'];
    errorData: InformedConsentModalState['errorData'];
}
function uploadErrorAction(
    errorMessage: UploadErrorAction['errorMessage'] = '',
    errorData: UploadErrorAction['errorData'] = [],
): UploadErrorAction {
    return {
        type: InformedConsentModalActions.UPLOAD_ERROR,
        errorMessage,
        errorData,
    };
}
interface UploadSuccessAction {
    type: InformedConsentModalActions.UPLOAD_SUCCESS;
}
function uploadSuccessAction(): UploadSuccessAction {
    return {
        type: InformedConsentModalActions.UPLOAD_SUCCESS,
    };
}
interface SetSubmissionAction {
    type: InformedConsentModalActions.SET_SUBMISSION;
    submission: InformedConsentModalState['submission'];
    blobListName: InformedConsentModalState['blobListName'];
    blobReportName: InformedConsentModalState['blobReportName'];
}
function setSubmissionAction(
    submission: SetSubmissionAction['submission'],
    blobListName: SetSubmissionAction['blobListName'],
    blobReportName: SetSubmissionAction['blobReportName'],
): SetSubmissionAction {
    return {
        type: InformedConsentModalActions.SET_SUBMISSION,
        blobListName,
        blobReportName,
        submission,
    };
}

interface SetSectionAction {
    type: InformedConsentModalActions.SET_SECTION;
    section: number;
}
function setSectionAction(section: SetSectionAction['section']): SetSectionAction {
    return {
        type: InformedConsentModalActions.SET_SECTION,
        section,
    };
}

type InformedConsentModalAction =
    | ResetModalAction
    | SelectReportAction
    | SelectListAction
    | UploadErrorAction
    | UploadSuccessAction
    | SetSubmissionAction
    | SetSectionAction;

export interface IExtendsInformedConsentModal {
    modal: InformedConsentModalState;
    dispatchModal: React.Dispatch<InformedConsentModalAction>;
    currentTimeframe: WindowSimple;
}
export default function informedConsentModalReducer(
    prevState: InformedConsentModalState,
    action: InformedConsentModalAction,
): InformedConsentModalState {
    switch (action.type) {
        case InformedConsentModalActions.RESET_MODAL:
            return getDefaultInformedConsentModalState(action.initialState);
        case InformedConsentModalActions.SELECT_REPORT: {
            return {
                ...prevState,
                section: 1,
                reportData: action.file,
                reportName: action.file.name,
            };
        }
        case InformedConsentModalActions.SELECT_LIST: {
            return {
                ...prevState,
                section: 2,
                listData: action.file,
                listName: action.file.name,
            };
        }
        case InformedConsentModalActions.UPLOAD_ERROR: {
            return {
                ...prevState,
                section: 3,
                errorData: action.errorData,
                errorMessage: action.errorMessage,
            };
        }
        case InformedConsentModalActions.UPLOAD_SUCCESS: {
            return {
                ...prevState,
                section: 4,
                errorData: [],
                errorMessage: '',
            };
        }
        case InformedConsentModalActions.SET_SUBMISSION: {
            return {
                ...prevState,
                submission: action.submission,
                blobListName: action.blobListName,
                blobReportName: action.blobReportName,
            };
        }
        case InformedConsentModalActions.SET_SECTION: {
            return {
                ...prevState,
                section: action.section as Section,
            };
        }
        default:
            return { ...prevState };
    }
}

export const informedConsentModalActionCreators = {
    resetModalAction,
    selectReportAction,
    selectListAction,
    uploadErrorAction,
    uploadSuccessAction,
    setSubmissionAction,
    setSectionAction,
};
