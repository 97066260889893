import React from 'react';
import { Container, Row, Col, Button, DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { DownloadCloud } from 'react-feather';
import { Link } from 'react-router-dom';
import Template from './Template';
import './Templates.scss';

enum Download {
    USER_GUIDE = '/downloads/templates/guides/Hashing Web Portal User Guide.pdf',
    MPH_REPORT_TEMPLATE = '/downloads/templates/MPH_Report_Template.csv',
    INFORMED_CONSENT_FORM_ARABIC = '/downloads/templates/informed_consent/DWD - Informed Consent Release_AR.pdf',
    INFORMED_CONSENT_FORM_BURMESE = '/downloads/templates/informed_consent/DWD - Informed Consent Release_Burmese.pdf',
    INFORMED_CONSENT_FORM_ENGLISH = '/downloads/templates/informed_consent/DWD - Informed Consent Release.pdf',
    INFORMED_CONSENT_FORM_FRENCH = '/downloads/templates/informed_consent/DWD - Informed Consent Release_FR.pdf',
    INFORMED_CONSENT_FORM_HAITIAN = '/downloads/templates/informed_consent/DWD - Informed Consent Release_Haitian Creole.pdf',
    INFORMED_CONSENT_FORM_SPANISH = '/downloads/templates/informed_consent/DWD - Informed Consent Release_Spanish.pdf',
    INFORMED_CONSENT_LIST_TEMPLATE = '/downloads/templates/Informed_Consent_List.csv',
}

export default function Templates() {
    return (
        <Container className="mb-5">
            <Row className="justify-content-between mt-4">
                <Col className="flex-grow-0">
                    <h3 className="mb-0">Templates</h3>
                </Col>
                <Col className="flex-grow-1" />
                <Col className="pr-2 flex-grow-0">
                    <span className="templates__split-button-wrapper">
                        <Link to={Download.USER_GUIDE} target="_blank">
                            <Button id="view-user-guide" variant="info" className="templates__view-user-guide">
                                View User Guide
                            </Button>
                        </Link>
                        <Link to={Download.USER_GUIDE} target="_blank">
                            <Button id="download-user-guide" variant="info" className="templates__download-user-guide">
                                <DownloadCloud size={19} className="templates__button-icon" />
                            </Button>
                        </Link>
                    </span>
                </Col>
            </Row>
            <Template
                title="Download MPH Report Template"
                description="Using this Excel template, your organization can populate identifiable information for clients served during the Data Period listed in your Dashboard."
            >
                <Link to={Download.MPH_REPORT_TEMPLATE} download target="_blank">
                    <Button>Download Template</Button>
                </Link>
            </Template>
            <Template
                title="Download Informed Consent Release Form Template"
                description="Available in five languages, your organization can collect signed informed consent release forms from clients using the this template."
            >
                <DropdownButton
                    id="download-form-and-translations-dropdown"
                    title="Select Language for Download"
                    alignRight
                >
                    <DropdownItem target="_blank" as={Link} to={Download.INFORMED_CONSENT_FORM_ARABIC}>
                        Arabic
                    </DropdownItem>
                    <DropdownItem target="_blank" as={Link} to={Download.INFORMED_CONSENT_FORM_BURMESE}>
                        Burmese
                    </DropdownItem>
                    <DropdownItem target="_blank" as={Link} to={Download.INFORMED_CONSENT_FORM_ENGLISH}>
                        English
                    </DropdownItem>
                    <DropdownItem target="_blank" as={Link} to={Download.INFORMED_CONSENT_FORM_FRENCH}>
                        French
                    </DropdownItem>
                    <DropdownItem target="_blank" as={Link} to={Download.INFORMED_CONSENT_FORM_HAITIAN}>
                        Haitian Creole
                    </DropdownItem>
                    <DropdownItem target="_blank" as={Link} to={Download.INFORMED_CONSENT_FORM_SPANISH}>
                        Spanish
                    </DropdownItem>
                </DropdownButton>
            </Template>
            <Template
                title="Download Informed Consent List Template"
                description="Using this Excel template, your organization can populate the name, date of birth, and zip code for clients you are submitting Signed Informed Consent Forms for and were served during the Data Period listed in your Dashboard."
            >
                <Link to={Download.INFORMED_CONSENT_LIST_TEMPLATE} download target="_blank">
                    <Button>Download Template</Button>
                </Link>
            </Template>
        </Container>
    );
}
