import { components } from '../types/openApi';
import APILayer from './APILayer';
import { WindowSimple } from '../components/Admin/Windows/windowsLib';
import { UserSimple } from './UsersAPI';

export type SubmissionFileSimple = components['schemas']['SubmissionFileSimple'];
export type AddSubmissionRequest = components['schemas']['AddSubmissionRequest'];
export type UpdateSubmissionRequest = components['schemas']['UpdateSubmissionRequest'];
export interface Submission extends UpdateSubmissionRequest {
    submissionType: number;
    userId: number;
    user: UserSimple;
    windowId: number;
    window: WindowSimple;
    created: string;
    modified: string | null;
}
/** Corresponds to @see {@link components['schemas']['SubmissionType']} */
export enum SubmissionType {
    UNSET,
    MPH_REPORT,
    INFORMED_CONSENT,
}
export default class SubmissionsAPI {
    APILayer = new APILayer();

    async getAllSubmissions(): Promise<Submission[]> {
        return this.APILayer.get('/api/submissions/GetAll').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve submissions');
            }
            return response.json();
        });
    }

    async getAllSubmissionsByOrganization(organizationId: number): Promise<Submission[]> {
        return this.APILayer.get(`/api/submissions/Get/${organizationId}`).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve submissions');
            }
            return response.json();
        });
    }

    async createHashedSubmission(
        file: File,
        submission: {
            firstName: string;
            lastName: string;
            rowsSubmitted: number;
            hash: string;
            userId: number;
            windowId: number;
        },
        blobFileName: string,
        errorMessage?: string,
        errorExtended?: string,
    ) {
        let data = new FormData();

        data.append('File', file);
        data.append('userId', String(submission.userId));
        data.append('windowId', String(submission.windowId));
        data.append('firstName', submission.firstName);
        data.append('lastName', submission.lastName);
        data.append('rowsSubmitted', String(submission.rowsSubmitted));
        data.append('hash', submission.hash);
        data.append('blobFileName', blobFileName);
        if (errorMessage) data.append('errorMessage', errorMessage);
        if (errorExtended) data.append('errorExtended', errorExtended);

        return this.APILayer.post(`/api/submissions/AddHashed`, data, true).then((response) => {
            return response;
        });
    }

    async createInformedConsentSubmission(submission: AddSubmissionRequest) {
        return this.APILayer.post('/api/submissions/Add', submission).then((response) => {
            if (response.status !== 200) {
                return false;
            }
            return response.json();
        });
    }

    async updateInformedConsentSubmission(submission: UpdateSubmissionRequest) {
        return this.APILayer.put('/api/submissions/Update', submission).then((response) => {
            if (response.status !== 200) {
                return false;
            }
            return response.json();
        });
    }
}
