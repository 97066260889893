import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FileUploadAPI from '../../api/FileUploadAPI';
import AppContext from '../../AppContext';
import Loading from '../common/Loading';
import TooltipPreset from '../common/TooltipPreset';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import { BoxedWrapper } from '../BoxedWrapper';
import TooltipButton from '../TooltipButton';
import FilesSelected from '../FilesSelected/FilesSelected';

import { hashingModalActionCreators, IExtendsHashingModal } from './hashingModalReducer';
import SectionFileSelectWrapper from './SectionFileSelectWrapper';

const { selectFileAction, uploadErrorAction, uploadSuccessAction, resetModalAction } = hashingModalActionCreators;

const fileUploadAPI = new FileUploadAPI();

interface IProps extends IExtendsHashingModal {
    currentTimeframe: WindowSimple;
}

export default function SectionFileSelect({
    modal: { fileData, fileName, section },
    dispatchModal,
    currentTimeframe,
}: IProps) {
    const fileUploadInput = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false);
    const { alertAdd } = useContext(AppContext);

    const uploadFile = useCallback(() => {
        if (fileData) {
            setLoading(true);
            fileUploadAPI
                .postFileUpload(fileData)
                .then((result) => {
                    // * Identify response structs that
                    // * correspond to errors.
                    if (!result.fileIsValid) {
                        setLoading(false);
                        return dispatchModal(uploadErrorAction('', result.validationErrors));
                    }

                    alertAdd({
                        variant: 'success',
                        text: 'File successfully validated.',
                    });

                    dispatchModal(
                        uploadSuccessAction(
                            result.validData.data,
                            result.validData.rowsSubmitted,
                            result.validData.hash ? result.validData.hash : '',
                        ),
                    );
                })
                .catch((error) => {
                    setLoading(false);

                    return dispatchModal(uploadErrorAction(error.message, []));
                });
        }
    }, [alertAdd, dispatchModal, fileData]);

    if (loading) return <Loading />;

    return (
        <>
            <SectionFileSelectWrapper window={currentTimeframe}>
                {section === 0 ? (
                    <BoxedWrapper className="section-2">
                        <>
                            <div className="mt-5">
                                <h5>
                                    Click to select/drag and drop your MPH Report <b>.CSV</b> file
                                    <TooltipPreset text="Upload a file with all of your clients served in the data period above. Make sure that your file is exported from Excel as a .CSV file for the Hashing Application to accept it. ">
                                        <FontAwesomeIcon
                                            className="mt-1 ml-2 file-upload-tooltip"
                                            icon={faQuestionCircle}
                                        />
                                    </TooltipPreset>
                                </h5>
                                <Button className="select-file-btn mt-3">Select File</Button>
                            </div>
                            <Form.Control
                                type="file"
                                accept="text/csv"
                                className="fileUpload"
                                // ! not sure how to resolve this type with the `.files`
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onChange={(e: any) => {
                                    const files = e.target.files;
                                    if (files?.length && files[0].type === 'text/csv') {
                                        dispatchModal(selectFileAction(e.target.files[0]));
                                    }
                                }}
                                ref={fileUploadInput}
                            />
                        </>
                    </BoxedWrapper>
                ) : (
                    <BoxedWrapper className="section-1">
                        <div className="py-5">
                            <FilesSelected fileNames={[fileName]} />
                            <TooltipButton
                                onClick={uploadFile}
                                label="Upload for Hashing"
                                tooltipText="By uploading a file you are submitting it to be scanned for viruses and checked for data validity. Nothing is being sent or stored in this process."
                            />
                        </div>
                    </BoxedWrapper>
                )}
            </SectionFileSelectWrapper>
            {section === 1 && (
                <div className="text-center">
                    <Button onClick={() => dispatchModal(resetModalAction())}>Reselect Files</Button>
                </div>
            )}
        </>
    );
}
