import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './FilesSelected.scss';

interface IProps {
    fileNames: string[];
}
export default function FilesSelected({ fileNames }: IProps) {
    return (
        <Row className="files-selected mb-2">
            <Col md="6" className="text-right pr-2">
                Files Selected:
            </Col>
            <Col md="6" className="text-left pl-2">
                {fileNames.map((fileName, i) => (
                    <SelectedFile fileName={fileName} key={i} />
                ))}
            </Col>
        </Row>
    );
}

interface SelectedFileProps {
    fileName: string;
}
function SelectedFile({ fileName }: SelectedFileProps) {
    return (
        <b>
            {fileName}
            <br />
        </b>
    );
}
