import React, { useMemo } from 'react';
import ProgressItem from './ProgressItem';

import './ProgressBar.scss';

interface IProps {
    segments: number;
    activeSegment?: number;
    onClickSegment?: (segmentClicked: number, e: React.MouseEvent<HTMLDivElement>) => void;
}
export default function ProgressBar({ segments, activeSegment = -1, onClickSegment = () => void 0 }: IProps) {
    // * Returns undefined into onClick prop, allowing
    // * className to automatically arm click styling.
    const getOnClick = useMemo<(index: number) => React.MouseEventHandler<HTMLDivElement> | undefined>(() => {
        return (index: number) => {
            if (!onClickSegment) return undefined;
            return (e: React.MouseEvent<HTMLDivElement>) => onClickSegment(index, e);
        };
    }, [onClickSegment]);

    // * Use key to immutably render segments.
    const renderedItems = useMemo(() => {
        return new Array(segments)
            .fill(null)
            .map((_, i) => (
                <ProgressItem
                    key={`progress-item-${i}`}
                    children={i + 1}
                    active={activeSegment >= i}
                    onClick={getOnClick(i)}
                />
            ));
    }, [activeSegment, getOnClick, segments]);

    return (
        <div className="steps-container">
            <div className="steps">
                <div className="step-bar"></div>
                {renderedItems}
            </div>
        </div>
    );
}
