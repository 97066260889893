import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Col, Container, Form, FormControl, Row, Table } from 'react-bootstrap';
import FileUploadAPI, { FileUpload } from '../../api/FileUploadAPI';
import SubmissionsAPI from '../../api/SubmissionsAPI';
import AppContext from '../../AppContext';
import formatDate from '../../lib/formatDate';
import Loading from '../common/Loading';
import TooltipPreset from '../common/TooltipPreset';
import { hashingModalActionCreators, IExtendsHashingModal, SubmittedUser } from './hashingModalReducer';
import { WindowSimple } from '../Admin/Windows/windowsLib';

const { resetModalAction } = hashingModalActionCreators;

const submissionsApi = new SubmissionsAPI();
const fileUploadAPI = new FileUploadAPI();

interface IProps extends IExtendsHashingModal {
    getOrganizationSubmissions(organizationId: number): void;
    currentTimeframe: WindowSimple;
}
export default function SectionSubmit({ modal, currentTimeframe, getOrganizationSubmissions, dispatchModal }: IProps) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const dataKeys = Object.keys(modal.secureData ? modal.secureData[0] : []);
    const { user } = useContext(AppContext);

    const [uploading, setUploading] = useState(false);

    const createSubmitFile = useCallback(() => {
        setSubmitted(true);
        if (firstName !== '' && lastName !== '') {
            setUploading(true);

            let submission = {
                ...(modal.submittedUser as Required<SubmittedUser>),
                firstName,
                lastName,
                userId: user!.userData!.userId!,
                windowId: currentTimeframe.windowId!,
            };

            const organizationId = user.userData!.organizationSimple!.organizationId!;

            fileUploadAPI
                .submitFile(modal.fileData!, currentTimeframe.windowId!, modal.submittedUser.rowsSubmitted)
                .then((result) => {
                    if (result) {
                        let uploadError = '';
                        let uploadErrorExtended = '';
                        if (result.uploadSuccessful === false) {
                            uploadError = result.errorMessage;
                            uploadErrorExtended = result.errorExtended;
                        }
                        submissionsApi
                            .createHashedSubmission(
                                modal.fileData!,
                                submission,
                                result.fileName,
                                uploadError,
                                uploadErrorExtended,
                            )
                            .then(() => {
                                dispatchModal(
                                    resetModalAction({
                                        section: result.uploadSuccessful === false ? 5 : 4,
                                    }),
                                );
                            })
                            .catch(() => {
                                dispatchModal(resetModalAction());
                            })
                            .finally(() => {
                                getOrganizationSubmissions(organizationId);
                                setUploading(false);
                            });
                    } else {
                        getOrganizationSubmissions(organizationId);
                        setUploading(false);
                        dispatchModal(
                            resetModalAction({
                                section: 5,
                            }),
                        );
                    }
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    }, [
        currentTimeframe.windowId,
        dispatchModal,
        firstName,
        getOrganizationSubmissions,
        lastName,
        modal.fileData,
        modal.submittedUser,
        user,
    ]);

    if (uploading) return <Loading />;

    return (
        <Container>
            <Row className="d-flex flex-column text-center align-items-center">
                <p>File Virus Check Status: Completed</p>
                <h4>File Approved for Submission</h4>
                <p className="text-danger">
                    Note: Data preview is for confirmation only. No identifying data will be stored or sent to MPH.
                </p>
                <Table className="text-center" striped bordered size="sm">
                    <SecureTableHeaders keys={dataKeys} />
                    <SecureTableBody datas={modal.secureData ? modal.secureData : []} />
                </Table>
                <br />
                <p>
                    Please enter your name and press submit to hash your file and submit the report to MPH.
                    <TooltipPreset text="By entering your name, you are acknowledging that you are the designated representative for your organization to submit the MPH Report for hashing.">
                        <FontAwesomeIcon className="ml-1" icon={faQuestionCircle} />
                    </TooltipPreset>
                </p>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <FormControl
                                    required
                                    className={submitted && !firstName.length ? 'alert-danger' : ''}
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFirstName(e.currentTarget.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <FormControl
                                    required
                                    className={submitted && !lastName.length ? 'alert-danger' : ''}
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setLastName(e.currentTarget.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a last name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center align-items-baseline">
                        <Button className="mt-3" onClick={createSubmitFile}>
                            Submit
                        </Button>
                        <TooltipPreset text="By submitting your MPH Report, the data will be hashed and the hashed data will be stored. No identifiable information will be stored and you will no longer be able to access this file through the Hashing Application website. ">
                            <FontAwesomeIcon className="ml-1" icon={faQuestionCircle} />
                        </TooltipPreset>
                    </div>
                </Form>
            </Row>
        </Container>
    );
}

interface SecureTableHeadersProps {
    keys: string[];
}
function SecureTableHeaders({ keys }: SecureTableHeadersProps) {
    return (
        <thead>
            <tr>
                {keys.map((key) => {
                    return <th key={key}>{String(key).toUpperCase()}</th>;
                })}
            </tr>
        </thead>
    );
}

interface SecureTableBodyProps {
    datas: FileUpload[];
}
function SecureTableBody({ datas }: SecureTableBodyProps) {
    return (
        <tbody>
            {datas.map((data, i) => {
                return (
                    <tr key={i}>
                        <td>{data.cbO_ID}</td>
                        <td>{data.cuid}</td>
                        <td>{data.family_ID}</td>
                        <td>{data.fn}</td>
                        <td>{data.ln}</td>
                        <td>{formatDate(new Date(data.dob))}</td>
                        <td>{data.zip}</td>
                        <td>{data.gender}</td>
                        <td>{data.race}</td>
                    </tr>
                );
            })}
        </tbody>
    );
}
