import { components } from '../../../types/openApi';

export type WindowSimple = components['schemas']['UploadWindowSimple'];

export interface WindowForm
    extends Omit<
        WindowSimple,
        'windowStart' | 'windowEnd' | 'dataCollectedStart' | 'dataCollectedEnd' | 'organizations' | 'notifications'
    > {
    windowStart: Date;
    windowEnd: Date;
    dataCollectedStart: Date;
    dataCollectedEnd: Date;
    organizations: NonNullable<WindowSimple['organizations']>;
    notifications: WindowSimple['notifications'];
    // organizationSimple: NonNullable<components['schemas']['OrganizationSimple'] & {
    //   name: string
    // }>;
    // userId: NonNullable<components['schemas']['UserSimple']['userId']>;
    // organizationId: NonNullable<components['schemas']['UserSimple']['userId']>;
    // email: NonNullable<components['schemas']['UserSimple']['email']>;
    // firstName: NonNullable<components['schemas']['UserSimple']['firstName']>;
    // lastName: NonNullable<components['schemas']['UserSimple']['lastName']>;
}

export enum WindowType {
    ACTIVE,
    FUTURE,
    ARCHIVE,
}

export enum NotificationType {
    UNSET,
    MPH_REPORT,
    INFORMED_CONSENT,
}

const monthName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export function formatDate(date: Date | null | undefined, withTime?: boolean) {
    const d = date ?? new Date();
    let formattedDate = monthName[d.getMonth()];
    formattedDate += ' ' + d.getDate();
    formattedDate += ', ' + d.getFullYear();
    if (withTime) {
        formattedDate += ' ' + formatTime(d);
    }
    return formattedDate;
}
export function formatTime(date: Date | null | undefined) {
    let d = date ?? new Date();
    return (
        (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) +
        ':' +
        (d.getMinutes() < 10 ? '0' : '') +
        d.getMinutes() +
        (d.getHours() < 12 ? 'am' : 'pm')
    );
}
export function dateWithoutTime(date: Date | null) {
    const d = date ?? new Date();
    d.setHours(0, 0, 0, 0);
    return d;
}

export function dateWithTime(date: Date | null) {
    const d = date ?? new Date();
    d.setHours(d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), 0);

    return d;
}
