import { getToken } from '../adalConfig';
import { config } from '../Config';

export default class APILayer {
    get = async (path: string) => {
        const endpoint = (window as any).encodeURI(config.apiUrl + path);

        let headers = new Headers();

        headers.set('Access-Control-Allow-Origin', config.apiUrl);
        headers.set('Access-Control-Allow-Credentials', 'true');
        headers.set('Authorization', `Bearer ${getToken()}`);

        return fetch(endpoint, {
            method: 'GET',
            headers: headers,
            credentials: 'include',
        }).then((response) => {
            return response;
        });
    };

    post = async (path: string, body: any, formData: boolean = false) => {
        const endpoint = (window as any).encodeURI(config.apiUrl + path);

        let headers = new Headers();

        headers.set('Access-Control-Allow-Origin', config.apiUrl);
        headers.set('Access-Control-Allow-Credentials', 'true');
        headers.set('Authorization', `Bearer ${getToken()}`);

        if (formData) {
            headers.set('Accept', 'multipart/form-data');
            // headers.set('Content-Type', 'multipart/form-data');
        } else {
            headers.set('Accept', 'application/json, text/plain, */*');
            headers.set('Content-Type', 'application/json');
        }

        return fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData ? body : JSON.stringify(body),
        }).then((response) => {
            return response;
        });
    };

    put = async (path: string, body: any) => {
        const endpoint = (window as any).encodeURI(config.apiUrl + path);
        let headers = new Headers();
        headers.set('Access-Control-Allow-Origin', config.apiUrl);
        headers.set('Access-Control-Allow-Credentials', 'true');
        headers.set('Authorization', `Bearer ${getToken()}`);
        headers.set('Accept', 'application/json, text/plain, */*');
        headers.set('Content-Type', 'application/json');
        return fetch(endpoint, {
            method: 'PUT',
            headers,
            body: JSON.stringify(body),
        }).then((response) => response);
    };
}
