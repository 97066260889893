import React from 'react';
import { Alert, AlertProps } from 'react-bootstrap';

export const isVariantOfAlertPropsType = (variant: string) => {
    if (!variant) return false;

    return ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'].includes(variant);
};

export const getVariant = (alertType: string) =>
    isVariantOfAlertPropsType(alertType) ? (alertType as AlertProps['variant']) : 'warning';

interface AlertPresetProps {
    alert: any;
    onClose?: () => void;
    children?: any;
    dismissible?: boolean;
}

export default class AlertPreset extends React.Component<AlertPresetProps, {}> {
    render() {
        return (
            <Alert
                className="container"
                variant={getVariant(this.props.alert.variant)}
                onClose={this.props.onClose}
                dismissible={this.props.dismissible}
            >
                {this.props.alert.text}
            </Alert>
        );
    }
}
