import React from 'react';
import { Router } from 'react-router-dom';
import history from './history';

import AuthRouter from './AuthRouter';

import Timeout from './components/User/UserTimeout';
import { AppContextProvider } from './AppContext';

export default function App() {
    return (
        <Router history={history}>
            <AppContextProvider>
                <>
                    <Timeout />
                    <AuthRouter />
                </>
            </AppContextProvider>
        </Router>
    );
}
