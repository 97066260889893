import React from 'react';
import { Container, Row, Col, Button, Table, Modal, ModalBody, InputGroup, FormControl } from 'react-bootstrap';
import ModalPreset from '../../common/ModalPreset';
import Loading from '../../common/Loading';
import { Link } from 'react-router-dom';
import { OrganizationSimple } from '../../../api/OrganizationsAPI';

interface ArchivedOrganizationsProps {
    handleError: (error: any) => void;
    getAllOrganizations: () => void;
    createOrganization: (newOrgName: string) => void;
    deleteOrganization: (id: number) => void;
    updateOrganization: (organization: any) => void;
    organizations: OrganizationSimple[] | null;
}

interface ArchivedOrganizationsState {
    modal: string;
    alertMessage: string;
    newOrganization: any;
    editOrganization: any;
}

export default class ArchivedOrganizations extends React.Component<
    ArchivedOrganizationsProps,
    ArchivedOrganizationsState
> {
    state = {
        modal: '',
        alertMessage: '',
        newOrganization: '',
        editOrganization: {
            id: 0,
            name: '',
        },
    };

    componentDidMount() {
        !this.props.organizations && this.props.getAllOrganizations();
    }

    createOrganization = () => {
        if (this.state.newOrganization !== '') {
            this.setModalShow('');
            this.props.createOrganization(this.state.newOrganization);
        }
    };

    deleteOrganization = () => {
        this.setModalShow('');
        this.props.deleteOrganization(this.state.editOrganization.id);
    };

    updateOrganization = () => {
        this.setModalShow('');
        this.props.updateOrganization({
            ...this.state.editOrganization,
            organizationId: this.state.editOrganization.id,
        });
    };

    setModalShow = (modalState: string) => {
        this.setState({ modal: modalState });
    };

    createTableData = (organization: any) => (
        <tr>
            <td className="align-middle pl-3">{organization.name}</td>
            <td className="text-center">
                <Button
                    variant="info"
                    className="mr-3 mt-2 mb-2"
                    onClick={() => {
                        this.setModalShow('edit_org');
                        this.setState({
                            editOrganization: {
                                ...this.state.editOrganization,
                                id: organization.organizationId,
                                name: organization.name,
                                isActive: organization.isActive,
                            },
                        });
                    }}
                >
                    {' '}
                    Reactivate
                </Button>
                {/* <Button variant="warning" className="mt-2 mb-2" onClick={() => {
					this.setModalShow('archive_org');
					this.setState({
						editOrganization: {
							...this.state.editOrganization,
							id: organization.organizationId
						}
					});
				}} > Archive</Button> */}
            </td>
        </tr>
    );

    render() {
        return (
            <div>
                <Container>
                    <Row className="mt-2">
                        <Col md={4}>
                            <h2>Archived Organizations</h2>
                        </Col>
                        <Col md={8}></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link to="/admin/organizations">Back</Link>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Table striped bordered size="sm">
                                <thead>
                                    <tr>
                                        <th className="org-name-table-header pt-3 pb-3 pl-3">
                                            Archived Organization Name
                                        </th>
                                        <th className="text-center pt-3 pb-3 pr-3">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.organizations ? (
                                        this.props.organizations.map((organization) => {
                                            if (organization.isActive === false) {
                                                return this.createTableData(organization);
                                            }
                                            return null;
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <ModalPreset show={this.state.modal === 'create_org'}>
                        <Modal.Header className="font-weight-bold">Add Organization</Modal.Header>
                        <ModalBody>
                            <p>Organization Name:</p>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    onChange={(e: any) => {
                                        this.setState({
                                            newOrganization: e.target.value,
                                        });
                                    }}
                                />
                            </InputGroup>
                        </ModalBody>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setModalShow('')}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.createOrganization()}>Add</Button>
                        </Modal.Footer>
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'edit_org'}>
                        <Modal.Header className="font-weight-bold">Reactivate Organization</Modal.Header>
                        <ModalBody>
                            <p>Are you sure you want to reactivate this organization?</p>
                        </ModalBody>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setModalShow('')}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editOrganization: {
                                            ...this.state.editOrganization,
                                            isActive: true,
                                        },
                                    });
                                    this.updateOrganization();
                                }}
                            >
                                OK
                            </Button>
                        </Modal.Footer>
                    </ModalPreset>
                </Container>
            </div>
        );
    }
}
