import React, { ChangeEventHandler } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    Modal,
    ModalBody,
    Card,
    Accordion,
    Form,
    FormControl,
} from 'react-bootstrap';
import ModalPreset from '../../common/ModalPreset';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Checkbox from '../../common/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../common/Loading';
import { OrganizationSimple } from '../../../api/OrganizationsAPI';
import {
    WindowForm,
    WindowType,
    dateWithTime,
    dateWithoutTime,
    formatDate,
    WindowSimple,
    NotificationType,
} from './windowsLib';

interface WindowsProps {
    windows: WindowSimple[] | null;
    organizations: OrganizationSimple[] | null;
    activeOrganizations: OrganizationSimple[] | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    windowErrorData: any;
    getAllWindows(): void;
    createWindow(newWindow: WindowForm): Promise<boolean>;
    getActiveOrganizations(): void;
    createWindowOrg(editWindow: WindowForm): Promise<boolean>;
    deleteWindow(editWindow: NonNullable<WindowSimple['windowId']>): void;
    deleteWindowOrg(editWindow: WindowForm): void;
    updateWindow(editWindow: WindowForm): Promise<boolean>;
    archiveWindow(WindowId: WindowForm['windowId']): void;
    emailWindowOrganizations(editWindow: WindowForm, emailBody: string): Promise<boolean>;
    sendNotification(windowId: number): void;
    resetWindowErrorData(): void;
}

interface WindowsState {
    modal: string;
    newWindow: WindowForm;
    editWindow: WindowForm;
    showModalLoading: boolean;
    selectedOrganizations: number[];
    emailBody: string;
    activeTimeframes: WindowSimple[];
    futureTimeframes: WindowSimple[];
    archivedTimeframes: WindowSimple[];
    archivedTimeframesNotification: WindowSimple[];
}

export default class Windows extends React.Component<WindowsProps, WindowsState> {
    state: WindowsState = {
        modal: '',
        newWindow: {
            windowStart: dateWithTime(new Date()),
            windowEnd: dateWithTime(new Date()),
            dataCollectedStart: dateWithoutTime(new Date()),
            dataCollectedEnd: dateWithoutTime(new Date()),
            organizations: [],
            notifications: [],
        },
        editWindow: {
            windowId: 0,
            windowStart: dateWithTime(new Date()),
            windowEnd: dateWithTime(new Date()),
            dataCollectedStart: dateWithoutTime(new Date()),
            dataCollectedEnd: dateWithoutTime(new Date()),
            organizations: [],
            notifications: [],
        },
        showModalLoading: false,
        selectedOrganizations: [],
        emailBody: '',
        activeTimeframes: [],
        futureTimeframes: [],
        archivedTimeframes: [],
        archivedTimeframesNotification: [],
    };

    componentDidMount() {
        !this.props.windows && this.props.getAllWindows();
        !this.props.activeOrganizations && this.props.getActiveOrganizations();

        let futureTimeframes: WindowSimple[] = [];
        let activeTimeframes: WindowSimple[] = [];
        let archivedTimeframes: WindowSimple[] = [];
        let archivedTimeframesNotification: WindowSimple[] = [];

        if (this.props.windows !== null) {
            for (let window of this.props.windows) {
                let todaysDate = new Date();
                let startDate = new Date(window.windowStart!);
                let endDate = new Date(window.windowEnd!);
                let notificationSent = window.notifications?.length
                    ? window.notifications.some(
                          (notification) => notification.notificationType === NotificationType.INFORMED_CONSENT,
                      )
                    : false;

                //Active TimeframeÍ
                if (todaysDate.getTime() >= startDate.getTime() && todaysDate.getTime() <= endDate.getTime()) {
                    activeTimeframes.push(window);
                }

                //Future Timeframe
                if (todaysDate.getTime() < startDate.getTime()) {
                    futureTimeframes.push(window);
                }

                //Archived Timeframe
                if (todaysDate.getTime() > endDate.getTime()) {
                    archivedTimeframes.push(window);
                    if (notificationSent) {
                        archivedTimeframesNotification.push(window);
                    }
                }
            }

            this.setState({
                activeTimeframes: activeTimeframes,
                futureTimeframes: futureTimeframes,
                archivedTimeframes: archivedTimeframes,
                archivedTimeframesNotification: archivedTimeframesNotification,
            });
        }
    }

    componentDidUpdate(prevProps: WindowsProps) {
        let futureTimeframes: WindowSimple[] = [];
        let activeTimeframes: WindowSimple[] = [];
        let archivedTimeframes: WindowSimple[] = [];
        let archivedTimeframesNotification: WindowSimple[] = [];

        if (this.props.windows !== null && this.props.windows !== prevProps.windows) {
            for (let window of this.props.windows) {
                let todaysDate = new Date();
                let startDate = new Date(window.windowStart!);
                let endDate = new Date(window.windowEnd!);
                let notificationSent = window.notifications?.length
                    ? window.notifications.some(
                          (notification) => notification.notificationType === NotificationType.INFORMED_CONSENT,
                      )
                    : false;

                // * Active Timeframe
                if (todaysDate.getTime() >= startDate.getTime() && todaysDate.getTime() <= endDate.getTime()) {
                    activeTimeframes.push(window);
                }

                // * Future Timeframe
                if (todaysDate.getTime() < startDate.getTime()) {
                    futureTimeframes.push(window);
                }

                // * Archived Timeframe
                if (todaysDate.getTime() > endDate.getTime()) {
                    archivedTimeframes.push(window);
                    if (notificationSent) {
                        archivedTimeframesNotification.push(window);
                    }
                }
            }

            this.setState({
                activeTimeframes: activeTimeframes,
                futureTimeframes: futureTimeframes,
                archivedTimeframes: archivedTimeframes,
                archivedTimeframesNotification: archivedTimeframesNotification,
            });
        }
    }

    createWindow = () => {
        let selectedOrganizations: OrganizationSimple[] = this.state.selectedOrganizations.map((organizationId) => ({
            organizationId,
        }));
        this.setState(
            {
                newWindow: {
                    ...this.state.newWindow,
                    organizations: selectedOrganizations,
                },
                showModalLoading: true,
            },
            () => {
                this.props.createWindow(this.state.newWindow).then((result) => {
                    if (result) {
                        this.setModalShow('');
                        this.setState({
                            selectedOrganizations: [],
                            showModalLoading: false,
                            newWindow: {
                                windowStart: dateWithTime(new Date()),
                                windowEnd: dateWithTime(new Date()),
                                dataCollectedStart: dateWithoutTime(new Date()),
                                dataCollectedEnd: dateWithoutTime(new Date()),
                                organizations: [],
                                notifications: [],
                            },
                        });
                        this.props.resetWindowErrorData();
                    } else {
                        if (!!this.props.windowErrorData) {
                            let errorOrganizations: number[] = this.props.windowErrorData.map(
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (organization: any) => organization.organizationId,
                            );

                            this.setState({
                                selectedOrganizations: errorOrganizations,
                            });
                        }

                        this.setState({
                            showModalLoading: false,
                        });
                    }
                });
            },
        );
    };

    createWindowOrg = () => {
        this.setModalShow('');
        let selectedOrganizations: OrganizationSimple[] = this.state.selectedOrganizations.map((organizationId) => ({
            organizationId: organizationId,
        }));
        this.setState(
            {
                editWindow: {
                    ...this.state.editWindow,
                    organizations: selectedOrganizations,
                },
            },
            () => {
                this.props.createWindowOrg(this.state.editWindow).then(() => {
                    this.setState({
                        selectedOrganizations: [],
                    });
                });
            },
        );
    };

    deleteWindow = () => {
        this.setModalShow('');
        this.props.deleteWindow(this.state.editWindow.windowId!);
    };

    deleteWindowOrg = () => {
        this.setModalShow('');
        this.props.deleteWindowOrg(this.state.editWindow);
    };

    updateWindow = () => {
        let selectedOrganizations: OrganizationSimple[] = this.state.selectedOrganizations.map((organizationId) => ({
            organizationId,
        }));
        this.setState(
            {
                editWindow: {
                    ...this.state.editWindow,
                    organizations: selectedOrganizations,
                },
                showModalLoading: true,
            },
            () => {
                this.props.updateWindow(this.state.editWindow).then((result) => {
                    if (result) {
                        this.setModalShow('');
                        this.setState({
                            showModalLoading: false,
                            editWindow: {
                                windowStart: dateWithTime(new Date()),
                                windowEnd: dateWithTime(new Date()),
                                dataCollectedStart: dateWithoutTime(new Date()),
                                dataCollectedEnd: dateWithoutTime(new Date()),
                                organizations: [],
                                notifications: [],
                            },
                            selectedOrganizations: [],
                        });
                    } else {
                        let errorOrganizations: number[] = this.props.windowErrorData.map(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (organization: any) => organization?.organizationId,
                        );
                        this.setState({
                            selectedOrganizations: errorOrganizations,
                            showModalLoading: false,
                            editWindow: {
                                ...this.state.editWindow,
                                // ! this is a weird assignment of number[]
                                // organizations: errorOrganizations
                            },
                        });
                    }
                });
            },
        );
    };

    archiveWindow = () => {
        this.setModalShow('');
        this.props.archiveWindow(this.state.editWindow.windowId!);
    };

    emailOrganizations = () => {
        const organizations: OrganizationSimple[] = this.state.selectedOrganizations.map((organizationId) => ({
            organizationId: organizationId,
        }));
        this.setState(
            {
                editWindow: {
                    ...this.state.editWindow,
                    organizations,
                },
                showModalLoading: true,
            },
            () => {
                this.props.emailWindowOrganizations(this.state.editWindow, this.state.emailBody).then((result) => {
                    if (result) {
                        this.setModalShow('');
                    }
                    this.setState({
                        showModalLoading: false,
                    });
                });
            },
        );
    };

    sendNotification = () => {
        this.setModalShow('');
        this.props.sendNotification(this.state.editWindow.windowId!);
    };

    setModalShow = (modalState: string) => {
        this.setState({
            modal: modalState,
        });
    };

    setNotificationWarning = (windowId: WindowForm['windowId']) => {
        if (this.state.archivedTimeframesNotification.find((notification) => notification.windowId === windowId)) {
            this.setModalShow('send_notification_another');
        } else {
            this.setModalShow('send_notification');
        }
    };

    cancelModal = () => {
        this.setModalShow('');
        this.setState({
            selectedOrganizations: [],
            newWindow: {
                windowStart: dateWithTime(new Date()),
                windowEnd: dateWithTime(new Date()),
                dataCollectedStart: dateWithoutTime(new Date()),
                dataCollectedEnd: dateWithoutTime(new Date()),
                organizations: [],
                notifications: [],
            },
            editWindow: {
                windowStart: dateWithTime(new Date()),
                windowEnd: dateWithTime(new Date()),
                dataCollectedStart: dateWithoutTime(new Date()),
                dataCollectedEnd: dateWithoutTime(new Date()),
                organizations: [],
                notifications: [],
            },
        });
        this.props.resetWindowErrorData();
    };

    updateNewDataPeriodStartDate = (value: Date | null) => {
        let date = dateWithoutTime(value);

        this.setState({
            newWindow: {
                ...this.state.newWindow,
                dataCollectedStart: date,
            },
        });
    };

    updateNewDataPeriodEndDate = (value: Date | null) => {
        let date = dateWithoutTime(value);

        this.setState({
            newWindow: {
                ...this.state.newWindow,
                dataCollectedEnd: date,
            },
        });
    };

    updateNewWindowStartDate = (value: Date | null) => {
        let date = dateWithTime(value);

        this.setState({
            newWindow: {
                ...this.state.newWindow,
                windowStart: date,
            },
        });
    };

    updateNewWindowEndDate = (value: Date | null) => {
        let date = dateWithTime(value);

        this.setState({
            newWindow: {
                ...this.state.newWindow,
                windowEnd: date,
            },
        });
    };

    updateEditPeriodStartDate = (value: Date | null) => {
        let date = dateWithoutTime(value);

        this.setState({
            editWindow: {
                ...this.state.editWindow,
                dataCollectedStart: date,
            },
        });
    };

    updateEditPeriodEndDate = (value: Date | null) => {
        let date = dateWithoutTime(value);

        this.setState({
            editWindow: {
                ...this.state.editWindow,
                dataCollectedEnd: date,
            },
        });
    };

    updateEditWindowStartDate = (value: Date | null) => {
        let date = dateWithTime(value);

        this.setState({
            editWindow: {
                ...this.state.editWindow,
                windowStart: date,
            },
        });
    };

    updateEditWindowEndDate = (value: Date | null) => {
        let date = dateWithTime(value);

        this.setState({
            editWindow: {
                ...this.state.editWindow,
                windowEnd: date,
            },
        });
    };

    selectAllCheckboxes = () => {
        let selectedOrganizations: WindowsState['selectedOrganizations'] =
            this.props.organizations?.map((organization) => organization.organizationId!) ?? [];
        this.setState({
            selectedOrganizations,
        });
    };

    deselectAllCheckboxes = () => {
        this.setState({
            selectedOrganizations: [],
        });
    };

    setCheckboxState = (organizationId: number) => {
        let selectedOrganizations = this.state.selectedOrganizations;
        if (selectedOrganizations.length > 0 && selectedOrganizations.indexOf(organizationId) > -1) {
            return true;
        }
        return false;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCheckboxChange = (organizationId: number, e: any) => {
        let newSelectedOrgs = this.state.selectedOrganizations;
        let findOrg = newSelectedOrgs.indexOf(organizationId);
        if (!e.target.checked) {
            if (newSelectedOrgs.length > 0 && newSelectedOrgs.indexOf(organizationId) > -1) {
                newSelectedOrgs.splice(findOrg, 1);
                this.setState({
                    selectedOrganizations: newSelectedOrgs,
                });
            }
        } else {
            newSelectedOrgs.push(organizationId);
            this.setState({
                selectedOrganizations: newSelectedOrgs,
            });
        }
    };

    createCheckbox = (organization: OrganizationSimple, error: boolean = false) => (
        <Checkbox
            key={organization.organizationId}
            error={error}
            label={organization.name}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onCheckboxChange={(e: any) => this.handleCheckboxChange(organization.organizationId!, e)}
            isSelected={this.setCheckboxState(organization.organizationId!)}
        />
    );

    createOrgTableData = (
        windowId: number,
        organizationId: number,
        name: string,
        windowEnd: string | undefined | null,
    ) => {
        let todaysDate = new Date();
        let endDate = new Date(windowEnd!);
        if (todaysDate.getTime() > endDate.getTime()) {
            return (
                <tr key={organizationId}>
                    <td className="align-middle">{name}</td>
                </tr>
            );
        } else {
            return (
                <tr key={organizationId}>
                    <td className="align-middle">{name}</td>
                    <td className="text-center">
                        <Button
                            variant="danger"
                            onClick={() => {
                                this.setModalShow('delete_org');
                                this.setState((_) => ({
                                    ..._,
                                    editWindow: {
                                        ..._.editWindow,
                                        windowId: windowId,
                                        organizations: [
                                            {
                                                organizationId,
                                            },
                                        ],
                                    },
                                }));
                            }}
                            className="org-delete-button"
                        >
                            Delete
                        </Button>
                    </td>
                </tr>
            );
        }
    };

    windowActionDisplay = (
        index: number,
        window: WindowSimple,
        currentDate: Date,
        endDate: Date,
        _type?: WindowType,
    ) => {
        let startDate = new Date(window.windowStart ?? '');
        if (currentDate.getTime() < startDate.getTime()) {
            return (
                <Col className="mt-2 mb-2" md={2}>
                    <Button
                        variant="info"
                        className="mr-3"
                        onClick={() => {
                            this.setModalShow('edit_window');
                            let selectedOrgs = window.organizations?.map(
                                (organization) => organization.organizationId!,
                            );
                            this.setState({
                                editWindow: {
                                    ...this.state.editWindow,
                                    windowId: window.windowId!,
                                    windowStart: dateWithTime(new Date(window.windowStart!)),
                                    windowEnd: dateWithTime(new Date(window.windowEnd!)),
                                    dataCollectedStart: dateWithTime(new Date(window.dataCollectedStart!)),
                                    dataCollectedEnd: dateWithTime(new Date(window.dataCollectedEnd!)),
                                    organizations: this.props.windows![index].organizations!,
                                },
                                selectedOrganizations: selectedOrgs ?? [],
                            });
                        }}
                    >
                        {' '}
                        Edit
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            this.setModalShow('delete_window');
                            this.setState({
                                editWindow: {
                                    ...this.state.editWindow,
                                    windowId: window.windowId,
                                },
                            });
                        }}
                    >
                        {' '}
                        Delete
                    </Button>
                </Col>
            );
        } else if (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()) {
            return (
                <Col className="mt-2 mb-2 archive-button-column" md={2}>
                    <Button
                        variant="warning"
                        className="mr-3"
                        onClick={() => {
                            this.setModalShow('archive_window');
                            this.setState({
                                editWindow: {
                                    ...this.state.editWindow,
                                    windowId: window.windowId,
                                },
                            });
                        }}
                    >
                        {' '}
                        Archive{' '}
                    </Button>
                </Col>
            );
        } else if (_type === WindowType.ARCHIVE) {
            return (
                <Col className="mt-2 mb-2 archive-button-column" md={4}>
                    <Button
                        variant="warning"
                        className="mr-3"
                        onClick={() => {
                            this.setState(
                                {
                                    editWindow: {
                                        ...this.state.editWindow,
                                        windowId: window.windowId,
                                        notifications: window.notifications,
                                    },
                                },
                                () => this.setNotificationWarning(window.windowId),
                            );
                        }}
                    >
                        {' '}
                        Send Notification{' '}
                    </Button>
                </Col>
            );
        } else {
            return <Col></Col>;
        }
    };

    organizationActionDisplay = (id: number, currentDate: Date, endDate: Date) => {
        if (currentDate.getTime() < endDate.getTime()) {
            return (
                <Col className="org-buttons">
                    <Button
                        className="mb-3 mr-3"
                        onClick={() => {
                            this.setModalShow('create_org');
                            this.setState({
                                editWindow: {
                                    ...this.state.editWindow,
                                    windowId: id,
                                },
                            });
                        }}
                    >
                        Add New Organization
                    </Button>
                    {/* <Button className="mb-3" onClick={() => {
					this.setState({
						editWindow: {
							windowId: id
						}
					});
					this.setModalShow('send_email');
				}}>Send Email</Button> */}
                </Col>
            );
        }
    };

    createWindowData = (index: number, window: WindowSimple, _type: WindowType) => {
        let currentDate = new Date();
        let endDate = new Date(window.windowEnd ?? '');
        return (
            <Card key={window.windowId}>
                <Row className="date-container">
                    <Col md={_type === WindowType.ARCHIVE ? 8 : 10} className="pt-2 pb-2">
                        <Accordion.Toggle as={Button} variant="link" eventKey={String(window.windowId)}>
                            <p className="mb-0">
                                {formatDate(new Date(window.windowStart!)) +
                                    ' - ' +
                                    formatDate(new Date(window.windowEnd!))}
                            </p>
                        </Accordion.Toggle>
                    </Col>
                    {this.windowActionDisplay(index, window, currentDate, endDate, _type)}
                </Row>
                <Accordion.Collapse eventKey={String(window.windowId)}>
                    <Card.Body>
                        <Row>
                            <Col className="mb-2">Organizations:</Col>
                            {this.organizationActionDisplay(window.windowId ?? 0, currentDate, endDate)}
                        </Row>
                        <Table className="org-enabled-table" striped bordered>
                            <thead>
                                {currentDate.getTime() < endDate.getTime() ? (
                                    <tr>
                                        <th className="align-items-center organizations-enabled">
                                            Organizations Enabled
                                        </th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th className="align-items-center organizations-enabled archived-org-enabled">
                                            Organizations Enabled
                                        </th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {(window?.organizations?.length ?? 0) > 0 ? (
                                    window!.organizations!.map((organization) => {
                                        if (organization.isActive === true) {
                                            return this.createOrgTableData(
                                                window!.windowId!,
                                                organization.organizationId!,
                                                organization.name!,
                                                window.windowEnd,
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={2}>No Organizations</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    };

    futureTimeframeSection = () => {
        if (this.state.futureTimeframes.length > 0) {
            return (
                <Row className="mt-5">
                    <Col>
                        <Row>
                            <Col>
                                <h3>Future Timeframes</h3>
                            </Col>
                        </Row>
                        <Row className="window-headers">
                            <Col md={4}>
                                <p>Timeframe Date</p>
                            </Col>
                            <Col className="actions-header" md={{ span: 2, offset: 6 }}>
                                <p>Actions</p>
                            </Col>
                        </Row>
                        <Accordion className="accordion-windows">
                            {this.state.futureTimeframes ? (
                                this.state.futureTimeframes.length > 0 ? (
                                    this.state.futureTimeframes.map((window, index) => {
                                        return this.createWindowData(index, window, WindowType.FUTURE);
                                    })
                                ) : (
                                    <Card>No Future Timeframes</Card>
                                )
                            ) : (
                                <Loading />
                            )}
                        </Accordion>
                    </Col>
                </Row>
            );
        }
    };

    activeTimeframeSection = () => {
        if (this.state.activeTimeframes.length > 0) {
            return (
                <Row className="mt-5">
                    <Col>
                        <Row>
                            <Col>
                                <h3>Active Timeframes</h3>
                            </Col>
                        </Row>
                        <Row className="window-headers">
                            <Col md={4}>
                                <p>Timeframe Date</p>
                            </Col>
                            <Col className="actions-header pl-0" md={{ span: 1, offset: 7 }}>
                                <p className="align-left">Actions</p>
                            </Col>
                        </Row>
                        <Accordion className="accordion-windows">
                            {this.state.activeTimeframes ? (
                                this.state.activeTimeframes.length > 0 ? (
                                    this.state.activeTimeframes.map((window, index) => {
                                        return this.createWindowData(index, window, WindowType.ACTIVE);
                                    })
                                ) : (
                                    <Card>No Active Timeframes</Card>
                                )
                            ) : (
                                <Loading />
                            )}
                        </Accordion>
                    </Col>
                </Row>
            );
        }
    };

    archivedTimeframeSection = () => {
        if (this.state.archivedTimeframes.length > 0) {
            return (
                <Row className="mt-5">
                    <Col>
                        <Row>
                            <Col>
                                <h3>Archived Timeframes</h3>
                            </Col>
                        </Row>
                        <Row className="window-headers">
                            <Col md={4}>
                                <p>Timeframe Date</p>
                            </Col>
                        </Row>
                        <Accordion className="accordion-windows">
                            {this.state.archivedTimeframes ? (
                                this.state.archivedTimeframes.length > 0 ? (
                                    this.state.archivedTimeframes.map((window, index) => {
                                        return this.createWindowData(index, window, WindowType.ARCHIVE);
                                    })
                                ) : (
                                    <Card>No Archived Timeframes</Card>
                                )
                            ) : (
                                <Loading />
                            )}
                        </Accordion>
                    </Col>
                </Row>
            );
        }
    };

    createSections = () => {
        return (
            <div>
                <div>{this.activeTimeframeSection()}</div>
                <div>{this.futureTimeframeSection()}</div>
                <div>{this.archivedTimeframeSection()}</div>
            </div>
        );
    };

    render() {
        return (
            <div>
                <Container className="mb-5">
                    <Row className="mt-2">
                        <Col md={4} className="pt-0">
                            <h2>Upload Timeframes</h2>
                        </Col>
                        <Col className="text-right" md={8}>
                            <Button onClick={() => this.setModalShow('create_window')}>Add New Timeframe</Button>
                        </Col>
                    </Row>

                    {this.props.windows ? this.createSections() : <Loading />}

                    <ModalPreset show={this.state.modal === 'create_window'}>
                        <Modal.Header className="font-weight-bold">Add Timeframe</Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    <p> Data Reporting Period:</p>
                                    <Row className="calendar-rows mb-3">
                                        <Col>
                                            <p>Start Date:</p>
                                            <DatePicker
                                                selected={this.state.newWindow.dataCollectedStart}
                                                onChange={this.updateNewDataPeriodStartDate}
                                            />
                                        </Col>
                                        <Col>
                                            <p>End Date:</p>
                                            <DatePicker
                                                selected={this.state.newWindow.dataCollectedEnd}
                                                onChange={this.updateNewDataPeriodEndDate}
                                                minDate={this.state.newWindow.dataCollectedStart}
                                            />
                                        </Col>
                                    </Row>
                                    <p>Hashing Timeframe:</p>
                                    <Row className="calendar-rows mb-3">
                                        <Col>
                                            <p>Start Date:</p>
                                            <DatePicker
                                                selected={this.state.newWindow.windowStart}
                                                onChange={this.updateNewWindowStartDate}
                                                minDate={new Date()}
                                                // showTimeSelect
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </Col>
                                        <Col>
                                            <p>End Date:</p>
                                            <DatePicker
                                                selected={this.state.newWindow.windowEnd}
                                                onChange={this.updateNewWindowEndDate}
                                                minDate={this.state.newWindow.windowStart}
                                                // showTimeSelect
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </Col>
                                    </Row>
                                    <p>Organization:</p>
                                    <Form>
                                        {this.props.windowErrorData !== null && (
                                            <p className="text-danger">
                                                The organizations in red are in a timeframe that overlaps with the new
                                                start and end date chosen. Please choose a new start and end date or
                                                exclude the organizations in red to add.
                                            </p>
                                        )}
                                        <div className="organization-container">
                                            {this.props.activeOrganizations ? (
                                                this.props.activeOrganizations.map((activeOrganization) => {
                                                    if (activeOrganization.isActive === true) {
                                                        let orgError = false;

                                                        if (this.props.windowErrorData !== null) {
                                                            for (
                                                                let i = 0;
                                                                i < this.props.windowErrorData.length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    activeOrganization.organizationId ===
                                                                    this.props.windowErrorData[i].organizationId
                                                                ) {
                                                                    orgError = true;
                                                                    break;
                                                                }
                                                            }
                                                        }

                                                        return this.createCheckbox(activeOrganization, orgError);
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.selectAllCheckboxes}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.deselectAllCheckboxes}
                                            >
                                                Deselect All
                                            </Button>
                                        </div>
                                    </Form>
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button onClick={() => this.createWindow()}>Add</Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'edit_window'}>
                        <Modal.Header className="font-weight-bold">Edit Timeframe</Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    <p> Data Reporting Period:</p>
                                    <Row className="calendar-rows mb-3">
                                        <Col>
                                            <p>Start Date:</p>
                                            <DatePicker
                                                selected={this.state.editWindow.dataCollectedStart}
                                                onChange={this.updateEditPeriodStartDate}
                                            />
                                        </Col>
                                        <Col>
                                            <p>End Date:</p>
                                            <DatePicker
                                                selected={this.state.editWindow.dataCollectedEnd}
                                                onChange={this.updateEditPeriodEndDate}
                                                minDate={this.state.editWindow.dataCollectedStart}
                                            />
                                        </Col>
                                    </Row>
                                    <p> Hashing Timeframe:</p>
                                    <Row className="calendar-rows mb-3">
                                        <Col>
                                            <p>Start Date:</p>
                                            <DatePicker
                                                selected={this.state.editWindow.windowStart}
                                                onChange={this.updateEditWindowStartDate}
                                                minDate={new Date()}
                                                // showTimeSelect
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </Col>
                                        <Col>
                                            <p>End Date:</p>
                                            <DatePicker
                                                selected={this.state.editWindow.windowEnd}
                                                onChange={this.updateEditWindowEndDate}
                                                minDate={this.state.editWindow.windowStart}
                                                // showTimeSelect
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </Col>
                                    </Row>
                                    <p>Organization</p>
                                    <Form>
                                        {this.props.windowErrorData !== null && (
                                            <p className="text-danger">
                                                The organizations in red are in a timeframe that overlaps with the new
                                                start and end date chosen. Please choose a new start and end date or
                                                exclude the organizations in red to save.
                                            </p>
                                        )}
                                        <div className="organization-container">
                                            {this.props.activeOrganizations ? (
                                                this.props.activeOrganizations.map((organization) => {
                                                    if (
                                                        this.state.editWindow.organizations.some(
                                                            (_) => _.organizationId === organization.organizationId,
                                                        )
                                                    ) {
                                                        return null;
                                                    }
                                                    let orgError = false;
                                                    if (this.props.windowErrorData !== null) {
                                                        for (let i = 0; i < this.props.windowErrorData.length; i++) {
                                                            if (
                                                                organization.organizationId ===
                                                                this.props.windowErrorData[i].organizationId
                                                            ) {
                                                                orgError = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    return this.createCheckbox(organization, orgError);
                                                })
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.selectAllCheckboxes}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.deselectAllCheckboxes}
                                            >
                                                Deselect All
                                            </Button>
                                        </div>
                                    </Form>
                                </ModalBody>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            this.cancelModal();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={() => this.updateWindow()}>Save</Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'delete_window'}>
                        <Modal.Header className="font-weight-bold modal-header">
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                            Delete Timeframe
                        </Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    Are you sure you want to delete this timeframe? This will delete all organizations
                                    associated with the timeframe and cannot be undone.
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={() => this.deleteWindow()}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'create_org'}>
                        <Modal.Header className="font-weight-bold">Add Organization</Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    <p>Organization Name:</p>
                                    <Form>
                                        <div className="organization-container">
                                            {this.props.activeOrganizations ? (
                                                this.props.activeOrganizations.map((organizations) => {
                                                    if (organizations.isActive === true) {
                                                        return this.createCheckbox(organizations);
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.selectAllCheckboxes}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.deselectAllCheckboxes}
                                            >
                                                Deselect All
                                            </Button>
                                        </div>
                                    </Form>
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button onClick={() => this.createWindowOrg()}>Add</Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'delete_org'}>
                        <Modal.Header className="font-weight-bold modal-header">
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                            Delete Timeframe Organization
                        </Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    Are you sure you want to delete this organization? This cannot be undone.
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={() => this.deleteWindowOrg()}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'archive_window'}>
                        <Modal.Header className="font-weight-bold modal-header">
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                            Archive Timeframe
                        </Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    Are you sure you want to archive this timeframe? This will end the upload timeframe
                                    and prevent future submissions.
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button variant="warning" onClick={() => this.archiveWindow()}>
                                        Archive
                                    </Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'send_email'}>
                        <Modal.Header className="font-weight-bold">Send Email</Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    <p>Select the organizations you would like to send an email to:</p>
                                    <Form>
                                        <div className="mt-3 mb-3">
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.selectAllCheckboxes}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                variant="info"
                                                size="sm"
                                                className="mr-2"
                                                onClick={this.deselectAllCheckboxes}
                                            >
                                                Deselect All
                                            </Button>
                                        </div>
                                        <div className="organization-container">
                                            {this.props.organizations ? (
                                                this.props.organizations.map((organizations) => {
                                                    if (organizations.isActive === true) {
                                                        return this.createCheckbox(organizations);
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </Form>
                                    <br />
                                    <FormControl
                                        as="textarea"
                                        placeholder="Insert email message here"
                                        onChange={
                                            ((e) => {
                                                this.setState({
                                                    emailBody: e.target.value,
                                                });
                                            }) as ChangeEventHandler<HTMLTextAreaElement>
                                        }
                                    />
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button onClick={() => this.emailOrganizations()}>Send</Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'send_notification'}>
                        <Modal.Header className="font-weight-bold modal-header">
                            Send Informed Consent Summary Notification
                        </Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    Send an email notification to DWD with a summary of all Informed Consent files that
                                    were sent to them?
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button variant="warning" onClick={() => this.sendNotification()}>
                                        Send
                                    </Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'send_notification_another'}>
                        <Modal.Header className="font-weight-bold modal-header">
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                            Notifications have already been sent
                        </Modal.Header>
                        {this.state.showModalLoading ? (
                            <Loading />
                        ) : (
                            <div>
                                <ModalBody>
                                    One or more Informed Consent summary emails have already been sent for the window.
                                    The last notification was sent on{' '}
                                    {this.state.editWindow?.notifications?.length
                                        ? formatDate(
                                              new Date(
                                                  this.state.editWindow?.notifications[
                                                      this.state.editWindow?.notifications.length - 1
                                                  ].notificationSentOn!,
                                              ),
                                              true,
                                          )
                                        : null}
                                    {'. '}
                                    Are you sure you want to send another one?
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.cancelModal()}>
                                        Cancel
                                    </Button>
                                    <Button variant="warning" onClick={() => this.sendNotification()}>
                                        Send
                                    </Button>
                                </Modal.Footer>
                            </div>
                        )}
                    </ModalPreset>
                </Container>
            </div>
        );
    }
}
