const monthName = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];
export default function formatDate(date: any, time: boolean = false) {
    let d = new Date(date);
    let formattedDate = monthName[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

    if (time) {
        formattedDate +=
            ' ' +
            (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) +
            ':' +
            (d.getMinutes() < 10 ? '0' : '') +
            d.getMinutes() +
            (d.getHours() < 12 ? 'am' : 'pm');
    }

    return formattedDate;
}
