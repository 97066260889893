import React from 'react';
import { Spinner } from 'react-bootstrap';

export default class Loading extends React.Component {
    render() {
        return (
            <div className="text-center w-100 pt-5 pb-5">
                <Spinner variant="secondary" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
}
