import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    Modal,
    ModalBody,
    InputGroup,
    FormControl,
    FormGroup,
    FormLabel,
} from 'react-bootstrap';
import ModalPreset from '../../common/ModalPreset';
import Loading from '../../common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface AdminsProps {
    getAllAdmins: () => void;
    createAdmin: (admin: any) => void;
    deleteAdmin: (email: string) => void;
    updateAdmin: (admin: any) => void;
    admins: any;
}

interface AdminsState {
    modal: string;
    alertMessage: string;
    newAdmin: any;
    editAdmin: any;
}

export default class Admins extends React.Component<AdminsProps, AdminsState> {
    state = {
        modal: '',
        alertMessage: '',
        newAdmin: {
            email: '',
            firstName: '',
            lastName: '',
        },
        editAdmin: {
            adminId: 0,
            email: '',
            firstName: '',
            lastName: '',
        },
    };

    componentDidMount() {
        !this.props.admins && this.props.getAllAdmins();
    }

    createAdmin = () => {
        if (
            this.state.newAdmin.email !== '' &&
            this.state.newAdmin.firstName !== '' &&
            this.state.newAdmin.lastName !== ''
        ) {
            this.setModalShow('');
            this.props.createAdmin(this.state.newAdmin);
        }
    };

    deleteAdmin = () => {
        this.setModalShow('');
        this.props.deleteAdmin(this.state.editAdmin.email);
    };

    updateAdmin = () => {
        this.setModalShow('');
        this.props.updateAdmin(this.state.editAdmin);
    };

    setModalShow = (modalState: string) => {
        this.setState({ modal: modalState });
    };

    createTableData = (admin: any, index: number) => (
        <tr>
            <td className="align-middle">{admin.firstName}</td>
            <td className="align-middle">{admin.lastName}</td>
            <td className="align-middle">{admin.email}</td>
            <td>
                <Button
                    variant="info"
                    className="mr-3 mb-2 mt-2"
                    onClick={() => {
                        this.setModalShow('edit_admin');
                        this.setState({
                            editAdmin: {
                                adminId: admin.adminId,
                                email: admin.email,
                                firstName: admin.firstName,
                                lastName: admin.lastName,
                            },
                        });
                    }}
                >
                    {' '}
                    Edit
                </Button>
                <Button
                    variant="danger"
                    className="mt-2 mb-2"
                    onClick={() => {
                        this.setModalShow('delete_admin');
                        this.setState({
                            editAdmin: {
                                email: admin.email,
                            },
                        });
                    }}
                >
                    {' '}
                    Delete
                </Button>
            </td>
        </tr>
    );

    render() {
        return (
            <div>
                <Container>
                    <Row className="mt-2">
                        <Col md={4}>
                            <h2>Admins</h2>
                        </Col>
                        <Col className="text-right" md={8}>
                            <Button onClick={() => this.setModalShow('create_admin')}>Add New Admin</Button>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Table className="text-center" striped bordered size="sm">
                                <thead>
                                    <tr>
                                        <th className="admins-table-headers">First Name</th>
                                        <th className="admins-table-headers">Last Name</th>
                                        <th className="admins-table-headers">Email</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.admins ? (
                                        this.props.admins.map((admin: any, index: number) => {
                                            if (admin.isActive === true) {
                                                return this.createTableData(admin, index);
                                            }
                                            return null;
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <ModalPreset show={this.state.modal === 'create_admin'}>
                        <Modal.Header className="font-weight-bold">Add Admin</Modal.Header>
                        <ModalBody>
                            <InputGroup>
                                <FormGroup className="w-100">
                                    <FormLabel>Email:</FormLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e: any) => {
                                            this.setState({
                                                newAdmin: {
                                                    ...this.state.newAdmin,
                                                    email: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="w-100">
                                    <FormLabel>First Name:</FormLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e: any) => {
                                            this.setState({
                                                newAdmin: {
                                                    ...this.state.newAdmin,
                                                    firstName: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="w-100">
                                    <FormLabel>Last Name:</FormLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e: any) => {
                                            this.setState({
                                                newAdmin: {
                                                    ...this.state.newAdmin,
                                                    lastName: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </InputGroup>
                        </ModalBody>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setModalShow('')}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.createAdmin()}>Add</Button>
                        </Modal.Footer>
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'edit_admin'}>
                        <Modal.Header className="font-weight-bold">Edit Admin</Modal.Header>
                        <ModalBody>
                            <InputGroup>
                                <FormGroup className="w-100">
                                    <FormLabel>Email:</FormLabel>
                                    <FormControl type="text" value={this.state.editAdmin.email} disabled />
                                </FormGroup>
                                <FormGroup className="w-100">
                                    <FormLabel>First Name:</FormLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.editAdmin.firstName}
                                        onChange={(e: any) => {
                                            this.setState({
                                                editAdmin: {
                                                    ...this.state.editAdmin,
                                                    firstName: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="w-100">
                                    <FormLabel>Last Name:</FormLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.editAdmin.lastName}
                                        onChange={(e: any) => {
                                            this.setState({
                                                editAdmin: {
                                                    ...this.state.editAdmin,
                                                    lastName: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </InputGroup>
                        </ModalBody>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setModalShow('')}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.updateAdmin()}>Save</Button>
                        </Modal.Footer>
                    </ModalPreset>

                    <ModalPreset show={this.state.modal === 'delete_admin'}>
                        <Modal.Header className="font-weight-bold">
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} />
                            Delete Admin
                        </Modal.Header>
                        <ModalBody>Are you sure you want to delete this admin? This action cannot be undone.</ModalBody>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setModalShow('')}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => this.deleteAdmin()}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </ModalPreset>
                </Container>
            </div>
        );
    }
}
