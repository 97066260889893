import React from 'react';
import { Route } from 'react-router-dom';
import Loading from '../common/Loading';

import UserDashboard from './UserDashboard';
import NavigationHeader from '../common/NavigationHeader';
import Templates from './Templates';

export default class UserRouter extends React.Component {
    render() {
        return (
            <React.Suspense fallback={<Loading />}>
                <Route path="/user" component={NavigationHeader} />
                <Route exact path="/user" component={UserDashboard} />
                <Route path="/user/templates" component={Templates} />
            </React.Suspense>
        );
    }
}
