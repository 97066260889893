import React from 'react';
import { Col, Row } from 'react-bootstrap';

interface IProps {
    children: React.ReactChild;
    className?: string;
}
export function BoxedWrapper({ children, className = '' }: IProps) {
    return (
        <Row>
            <Col className={`border text-center ${className}`}>{children}</Col>
        </Row>
    );
}
