import React from 'react';
import { FileUpload, InvalidFileData } from '../../api/FileUploadAPI';

export interface SubmittedUser {
    firstName: string;
    lastName: string;
    rowsSubmitted: number;
    hash: string;
}
type Section = 0 | 1 | 2 | 3 | 4 | 5;
interface HashingModalState {
    section: Section;
    secureData: FileUpload[] | null;
    fileData: File | null;
    fileName: string;
    errorMessage: string;
    errorData: InvalidFileData[] | null;
    submittedUser: SubmittedUser;
}

const defaultHashingModalState: HashingModalState = {
    section: 0,
    secureData: [],
    errorData: [],
    errorMessage: '',
    fileData: null,
    fileName: '',
    submittedUser: {
        firstName: '',
        lastName: '',
        rowsSubmitted: 0,
        hash: '',
    },
};
export function getDefaultHashingModalState(state: Partial<HashingModalState> = {}) {
    return Object.assign({}, defaultHashingModalState, state);
}

export enum HashingModalActions {
    RESET_MODAL = 'RESET_MODAL',
    SELECT_FILE = 'SELECT_FILE',
    UPLOAD_ERROR = 'UPLOAD_ERROR',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
    SET_SECTION = 'SET_SECTION',
}

interface ResetModalAction {
    type: HashingModalActions.RESET_MODAL;
    initialState: Partial<HashingModalState>;
}
function resetModalAction(initialState: ResetModalAction['initialState'] = {}): ResetModalAction {
    return {
        type: HashingModalActions.RESET_MODAL,
        initialState,
    };
}

interface SelectFileAction {
    type: HashingModalActions.SELECT_FILE;
    file: File;
}
function selectFileAction(file: File): SelectFileAction {
    return {
        type: HashingModalActions.SELECT_FILE,
        file,
    };
}

interface UploadErrorAction {
    type: HashingModalActions.UPLOAD_ERROR;
    errorMessage: HashingModalState['errorMessage'];
    errorData: HashingModalState['errorData'];
}
function uploadErrorAction(
    errorMessage: UploadErrorAction['errorMessage'] = '',
    errorData: UploadErrorAction['errorData'] = [],
): UploadErrorAction {
    return {
        type: HashingModalActions.UPLOAD_ERROR,
        errorMessage,
        errorData,
    };
}
interface UploadSuccessAction {
    type: HashingModalActions.UPLOAD_SUCCESS;
    secureData: HashingModalState['secureData'];
    rowsSubmitted: SubmittedUser['rowsSubmitted'];
    hash: SubmittedUser['hash'];
}
function uploadSuccessAction(
    secureData: UploadSuccessAction['secureData'],
    rowsSubmitted: UploadSuccessAction['rowsSubmitted'],
    hash: UploadSuccessAction['hash'],
): UploadSuccessAction {
    return {
        type: HashingModalActions.UPLOAD_SUCCESS,
        secureData,
        rowsSubmitted,
        hash,
    };
}

interface SetSectionAction {
    type: HashingModalActions.SET_SECTION;
    section: number;
}
function setSectionAction(section: SetSectionAction['section']): SetSectionAction {
    return {
        type: HashingModalActions.SET_SECTION,
        section,
    };
}

type HashingModalAction =
    | ResetModalAction
    | SelectFileAction
    | UploadErrorAction
    | UploadSuccessAction
    | SetSectionAction;

export interface IExtendsHashingModal {
    modal: HashingModalState;
    dispatchModal: React.Dispatch<HashingModalAction>;
}
export default function hashingModalReducer(
    prevState: HashingModalState,
    action: HashingModalAction,
): HashingModalState {
    switch (action.type) {
        case HashingModalActions.RESET_MODAL:
            return getDefaultHashingModalState(action.initialState);
        case HashingModalActions.SELECT_FILE: {
            return {
                ...prevState,
                section: 1,
                fileData: action.file,
                fileName: action.file.name,
            };
        }
        case HashingModalActions.UPLOAD_ERROR: {
            return {
                ...prevState,
                section: 2,
                errorData: action.errorData,
                errorMessage: action.errorMessage,
            };
        }
        case HashingModalActions.UPLOAD_SUCCESS: {
            return {
                ...prevState,
                section: 3,
                secureData: action.secureData,
                submittedUser: {
                    ...prevState.submittedUser,
                    rowsSubmitted: action.rowsSubmitted,
                    hash: action.hash,
                },
                errorData: [],
                errorMessage: '',
            };
        }
        case HashingModalActions.SET_SECTION: {
            let section = action.section + 2;
            if (section === 2) {
                switch (prevState.section) {
                    case 2:
                        section = 1;
                        break;
                    case 1:
                        section = 0;
                        break;
                    default:
                        section = 2;
                }
            }
            return {
                ...prevState,
                section: section as Section,
            };
        }
        default:
            return { ...prevState };
    }
}

export const hashingModalActionCreators = {
    resetModalAction,
    selectFileAction,
    uploadErrorAction,
    uploadSuccessAction,
    setSectionAction,
};
