require('dotenv').config();

const PROD_API_URL = 'https://uwci-prod-v1-userportal-api.azurewebsites.net';
const UAT_API_URL = 'https://uwci-uat-v1-userportal-api.azurewebsites.net';
const DEV_API_URL = 'http://localhost:8000';
const ENV_STRING = `${process.env.REACT_APP_ENV.toLowerCase()}`;

let API_URL;

switch (ENV_STRING) {
    case 'production':
        API_URL = PROD_API_URL;
        break;
    case 'staging':
    case 'test':
        API_URL = UAT_API_URL;
        break;
    default:
        API_URL = DEV_API_URL;
}

// NOTE: leaving this in for now, as it will be useful for confirming new environment configuration
console.log(`Using ${ENV_STRING} API URL: ${API_URL}`);

module.exports.config = {
    apiUrl: API_URL,
    appId: '3385ad8f-9cf3-4523-989a-fc953159c27a',
    scopes: ['user.read'],
    tenantId: 'a682ece6-030e-4599-ae64-92e73b618019',
    //Hash CBO Group in United Way Tenant
    userGroupId: 'a124d4e5-9130-49fd-b1d2-8b9dd3a44577',
    //Hash Admins Group in United Way Tenant
    adminGroupId: 'c871838b-dee4-4cec-9462-95bb6df739bc',
};
