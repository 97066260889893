import { BlobServiceClient } from '@azure/storage-blob';
import { SasUri } from '../../api/FileUploadAPI';
import { useCallback, useRef } from 'react';

export default function useBlobStorage(sas: SasUri) {
    const blobServiceClient = useRef(new BlobServiceClient(sas.uri!));

    const containerClient = blobServiceClient.current.getContainerClient(sas.containerName!);
    return useCallback(
        async (submissionId: number, file: File) => {
            const blobName = getBlobFileName(submissionId, file.name);
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            return blockBlobClient.upload(file, file.size).then((_) => {
                return Object.assign(_, { blobName });
            });
        },
        [containerClient],
    );
}

function getBlobFileName(submissionId: number, name: string) {
    const splitFileName = name.split('.');
    const extension = splitFileName.pop();
    const fileName = splitFileName.join('.');
    return `${submissionId}-${fileName} ${new Date().getTime()}.${extension}`;
}
