import React, { ContextType } from 'react';
import FileUploadAPI from '../../api/FileUploadAPI';
import SubmissionsAPI, { Submission, SubmissionType } from '../../api/SubmissionsAPI';
import AppContext from '../../AppContext';
import { HashingModal } from '../HashingModal/HashingModal';
import UploadHeader from './UploadHeader';
import InformedConsentModal from '../InformedConsentModal/InformedConsentModal';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import { UserSimple } from '../../api/UsersAPI';
import SubmissionsTable from './SubmissionsTable';

interface UserState {
    uniqueUsers: UserSimple[];
    uniqueWindows: WindowSimple[];
    submissions: Submission[] | null;
    currentTimeframe: WindowSimple;
    nextTimeframe: WindowSimple;
    modal: SubmissionType;
}

export default class UserDashboard extends React.Component<{}, UserState> {
    FileUploadAPI = new FileUploadAPI();
    SubmissionsAPI = new SubmissionsAPI();

    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;

    state: UserState = {
        modal: SubmissionType.UNSET,
        currentTimeframe: {
            windowId: undefined,
            name: '',
            windowStart: '',
            windowEnd: '',
            dataCollectedStart: '',
            dataCollectedEnd: '',
            organizations: [],
        },
        nextTimeframe: {
            windowId: undefined,
            name: '',
            windowStart: '',
            windowEnd: '',
            dataCollectedStart: '',
            dataCollectedEnd: '',
            organizations: [],
        },
        submissions: [],
        uniqueUsers: [],
        uniqueWindows: [],
    };

    componentDidMount() {
        const organizationId = this.context.user.userData?.organizationSimple?.organizationId;
        if (organizationId && this.state.submissions !== null && this.state.submissions.length === 0) {
            this.getOrganizationSubmissions(organizationId);
        }
    }

    componentDidUpdate(prevProps: {}, prevState: UserState) {
        if (this.state.submissions !== prevState.submissions) {
            let uniqueUsers: UserSimple[] = [];
            let uniqueWindows: WindowSimple[] = [];
            if (this.state.submissions !== null) {
                for (let submission of this.state.submissions) {
                    !uniqueUsers.some(
                        (s) => s.firstName + ' ' + s.lastName === submission.firstName + ' ' + submission.lastName,
                    ) && uniqueUsers.push(submission);
                    !uniqueWindows.some((w) => w.windowId === submission.window.windowId) &&
                        uniqueWindows.push(submission.window);
                }
                this.setState({
                    uniqueUsers,
                    uniqueWindows,
                });
            }
        }
    }

    setModalShow = (modal: SubmissionType = SubmissionType.UNSET) => {
        this.setState({ modal });
    };

    getOrganizationSubmissions = (organizationId: number) => {
        this.setState({ submissions: null });

        this.SubmissionsAPI.getAllSubmissionsByOrganization(organizationId)
            .then((submissions) => {
                this.setState({ submissions });
            })
            .catch(this.context.handleError);
    };

    render() {
        return (
            <div>
                <UploadHeader
                    nextTimeframe={this.state.nextTimeframe}
                    currentTimeframe={this.state.currentTimeframe}
                    openModal={this.setModalShow}
                    setTimeframes={(timeframes) => this.setState(timeframes)}
                />
                <SubmissionsTable
                    submissions={this.state.submissions}
                    uniqueUsers={this.state.uniqueUsers}
                    uniqueWindows={this.state.uniqueWindows}
                />
                <InformedConsentModal
                    show={this.state.modal === SubmissionType.INFORMED_CONSENT}
                    onClose={() => this.setModalShow()}
                    currentTimeframe={this.state.currentTimeframe}
                    getOrganizationSubmissions={this.getOrganizationSubmissions}
                />
                <HashingModal
                    show={this.state.modal === SubmissionType.MPH_REPORT}
                    onClose={() => {
                        this.setModalShow();
                        this.context.resetAlerts();
                    }}
                    currentTimeframe={this.state.currentTimeframe}
                    getOrganizationSubmissions={this.getOrganizationSubmissions}
                />
            </div>
        );
    }
}
