import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import logo from '../../assets/images/uwci_logo.png';

interface LoginProps {
    authenticate: () => void;
}

export default class Login extends React.Component<LoginProps, {}> {
    render() {
        return (
            <Container className="login-container">
                <Row>
                    <Col>
                        <img alt="UWCI" src={logo} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <p>Hashing Application Website</p>
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col>
                        <Button onClick={() => this.props.authenticate()}>Login</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
