import { components } from '../types/openApi';
import APILayer from './APILayer';

export type Admin = components['schemas']['Admin'];
export default class AdminsAPI {
    APILayer = new APILayer();

    async getAllAdmins(): Promise<Admin[]> {
        return this.APILayer.get('/api/admins/GetAll').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve admins.');
            }
            return response.json();
        });
    }

    async getAdmin(email: string): Promise<Admin> {
        return this.APILayer.get(`/api/admins/Get/${email}`).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve admin.');
            }
            return response.json();
        });
    }

    async createAdmin(newFirstName: string, newLastName: string, email: string) {
        const body = {
            Email: email,
            FirstName: newFirstName,
            LastName: newLastName,
        };

        return this.APILayer.post('/api/admins/Add', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to create admin.');
            }
        });
    }

    async deleteAdmin(email: string): Promise<boolean> {
        const body = {
            Email: email,
        };

        return this.APILayer.post('/api/admins/Delete', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to delete admin.');
            }
            return true;
        });
    }

    async updateAdmin(email: string, FirstName: string, LastName: string): Promise<boolean> {
        const body = {
            email: email,
            FirstName: FirstName,
            LastName: LastName,
        };

        return this.APILayer.post('/api/admins/Edit', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to update user.');
            }
            return true;
        });
    }
}
