import React from 'react';
import { Route } from 'react-router-dom';

import AdminRouter from './components/Admin/AdminRouter';
import UserRouter from './components/User/UserRouter';
import Loading from './components/common/Loading';

export default function AuthRouter() {
    return (
        <div>
            <Route exact path="/loading" render={() => <Loading />} />
            <AdminRouter />
            <UserRouter />
        </div>
    );
}
