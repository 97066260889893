import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SubmissionType } from '../../api/SubmissionsAPI';
import AppContext from '../../AppContext';
import formatDate from '../../lib/formatDate';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import TooltipPreset from '../common/TooltipPreset';

interface Timeframes {
    nextTimeframe: WindowSimple;
    currentTimeframe: WindowSimple;
}
interface IProps extends Timeframes {
    setTimeframes(timeframes: Timeframes): void;
    openModal(modal: SubmissionType): void;
}
export default function UploadHeader({ currentTimeframe, nextTimeframe, openModal, setTimeframes }: IProps) {
    const { user } = useContext(AppContext);
    const lastUser = useRef<typeof user>();
    useEffect(() => {
        if (user !== lastUser.current) {
            lastUser.current = { ...user };
            let windows = user.userData!.organizationSimple?.windows;
            if (windows?.length) {
                let currentDate = new Date();
                let timeframes = {} as Timeframes;

                windows.some((window) => {
                    let windowStart = new Date(window.windowStart!);
                    let windowEnd = new Date(window.windowEnd!);

                    if (
                        windowStart.getTime() <= currentDate.getTime() &&
                        windowEnd.getTime() >= currentDate.getTime()
                    ) {
                        timeframes.currentTimeframe = window;
                        return true;
                    }
                    return false;
                });

                windows.sort((a, b) => {
                    let aVal = new Date(a.windowStart!);
                    let bVal = new Date(b.windowStart!);

                    if (aVal.getTime() < bVal.getTime()) {
                        return -1;
                    }
                    if (aVal.getTime() > bVal.getTime()) {
                        return 1;
                    }
                    return 0;
                });

                for (let i = 0; i < windows.length; i++) {
                    let windowStart = new Date(windows[i].windowStart!);
                    if (currentDate.getTime() < windowStart.getTime()) {
                        timeframes.nextTimeframe = windows[i];
                        break;
                    }
                }
                if (Object.keys(timeframes).length) {
                    setTimeframes(timeframes);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const currentWindowCountdown = useCallback(() => {
        let todaysDate = new Date();
        let endDate = new Date(currentTimeframe.windowEnd!);
        let diffInTime = endDate.getTime() - todaysDate.getTime();
        let calculateDiff = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
        return calculateDiff;
    }, [currentTimeframe.windowEnd]);

    const futureWindowCountdown = useCallback(() => {
        const todaysDate = new Date();
        const endDate = new Date(nextTimeframe.windowStart!);
        const diffInTime = endDate.getTime() - todaysDate.getTime();
        return Math.floor(diffInTime / (1000 * 60 * 60 * 24));
    }, [nextTimeframe.windowStart]);

    const timeframeCountdown = useMemo(() => {
        let todaysDate = new Date();
        let currentEndDate = new Date(currentTimeframe.windowEnd!);
        let currentStartDate = new Date(currentTimeframe.windowStart!);
        let futureStartDate = new Date(nextTimeframe.windowStart!);

        let timeTilEnd = +currentEndDate - +todaysDate;
        let minTilEnd = Math.round(((timeTilEnd % 86400000) % 3600000) / 60000);

        let daysTilEnd = currentWindowCountdown();

        if (
            todaysDate.getTime() > currentStartDate.getTime() &&
            todaysDate.getTime() < currentEndDate.getTime() &&
            minTilEnd > 0
        ) {
            if (daysTilEnd < 1) {
                return <h2>The Timeframe ends on {formatDate(currentEndDate)} at 5:00PM</h2>;
            } else {
                let pluralized = daysTilEnd === 1 ? 'is 1 day' : `are ${daysTilEnd} days`;
                return <h2>There {pluralized} left in the current Timeframe</h2>;
            }
        } else if (!currentTimeframe.windowEnd && todaysDate.getTime() < futureStartDate.getTime()) {
            return <h2>Your Timeframe opens in {futureWindowCountdown()} day(s)</h2>;
        } else {
            return <h2>There are no open timeframes</h2>;
        }
    }, [
        currentTimeframe.windowEnd,
        currentTimeframe.windowStart,
        currentWindowCountdown,
        futureWindowCountdown,
        nextTimeframe.windowStart,
    ]);

    return (
        <Container fluid className="bg-light text-center pt-4 pb-4">
            <Row className="my-4 justify-content-center">
                <Col sm="8" md="6">
                    <h6>
                        All CBOs must have their MPH Report, Signed Informed Consent Forms, and Informed Consent List
                        successfully submitted by 5:00pm EST on the closing date of the Hashing Timeframe. Failure to
                        upload files will result in all CBOs being required to resubmit all files.
                    </h6>
                </Col>
            </Row>
            {currentTimeframe.windowId && (
                <Row className="mb-3">
                    <Col>
                        <h5 className="font-weight-normal d-flex align-content-center justify-content-center">
                            Data Period: {formatDate(currentTimeframe.dataCollectedStart)} -{' '}
                            {formatDate(currentTimeframe.dataCollectedEnd)}
                            <TooltipPreset text="The Data Period covers the time that clients were served.">
                                <FontAwesomeIcon className="ml-1 h6" icon={faQuestionCircle} />
                            </TooltipPreset>
                        </h5>
                    </Col>
                </Row>
            )}
            {currentTimeframe.windowId && (
                <Row className="mb-3">
                    <Col>
                        <h5 className="font-weight-normal">
                            Hashing Timeframe: {formatDate(currentTimeframe.windowStart)} -{' '}
                            {formatDate(currentTimeframe.windowEnd)}
                            <TooltipPreset text="The Hashing Timeframe is when you can submit files for hashing and the signed informed consent forms and list.">
                                <FontAwesomeIcon className="ml-1 h6" icon={faQuestionCircle} />
                            </TooltipPreset>
                        </h5>
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col>{timeframeCountdown}</Col>
            </Row>
            {currentTimeframe.windowId && (
                <>
                    <Row>
                        <Col>
                            <Button
                                variant="primary"
                                className="mb-4"
                                onClick={() => openModal(SubmissionType.MPH_REPORT)}
                            >
                                Upload MPH Report for Hashing
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="primary"
                                className="mb-4"
                                onClick={() => openModal(SubmissionType.INFORMED_CONSENT)}
                            >
                                Upload Informed Consent List and Signed Forms
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
}
