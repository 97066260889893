import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { Alert, Modal, ModalBody } from 'react-bootstrap';
import ModalPreset from '../common/ModalPreset';
import ProgressBar from '../ProgressBar/ProgressBar';
import hashingModalReducer, { getDefaultHashingModalState, hashingModalActionCreators } from './hashingModalReducer';
import SectionFileSelect from './SectionFileSelect';
import SectionFileScan from '../SectionFileScan';
import SectionSubmit from './SectionSubmit';
import SectionSuccess from '../SectionSuccess';
import SectionError from '../SectionError';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import AppContext from '../../AppContext';

const { resetModalAction, setSectionAction } = hashingModalActionCreators;

interface IProps {
    show: boolean;
    onClose(): void;
    currentTimeframe: WindowSimple;
    getOrganizationSubmissions(organizationId: number): void;
}
export function HashingModal({ show, onClose, currentTimeframe, getOrganizationSubmissions }: IProps) {
    const [modal, dispatchModal] = useReducer(hashingModalReducer, getDefaultHashingModalState());
    const { alerts, alertDismiss } = useContext(AppContext);

    useEffect(() => {
        if (!show) dispatchModal(resetModalAction());
    }, [show]);

    const activeSegment = useMemo(() => {
        if (modal.section >= 4) {
            return 3;
        }
        if (modal.section >= 2) {
            return modal.section - 1;
        }
        return modal.section;
    }, [modal.section]);

    const renderSwitch = useMemo(() => {
        switch (modal.section) {
            case 0:
            case 1:
                return (
                    <SectionFileSelect
                        currentTimeframe={currentTimeframe}
                        modal={modal}
                        dispatchModal={dispatchModal}
                    />
                );
            case 2:
                return <SectionFileScan modal={modal} goBack={() => dispatchModal(setSectionAction(0))} />;
            case 3:
                return (
                    <SectionSubmit
                        modal={modal}
                        dispatchModal={dispatchModal}
                        getOrganizationSubmissions={getOrganizationSubmissions}
                        currentTimeframe={currentTimeframe}
                    />
                );
            case 4:
                return (
                    <SectionSuccess
                        onClose={onClose}
                        description="Your MPH report has been hashed and securely submitted. No other data will be shared with MPH"
                    />
                );
            case 5:
                return (
                    <SectionError
                        onClose={onClose}
                        description="Your MPH report appears to valid but something else went wrong. If you continue to see this message please contact a site administrator."
                        tooltip="Your MPH report appears to valid but something else went wrong. If you continue to see this message please contact a site administrator."
                    />
                );
            default:
                return <div></div>;
        }
    }, [currentTimeframe, getOrganizationSubmissions, modal, onClose]);

    return (
        <ModalPreset size="lg" show={show} onHide={onClose}>
            <Modal.Header closeButton className="border-bottom-0">
                <ProgressBar
                    segments={4}
                    activeSegment={activeSegment}
                    // onClickSegment={i => dispatchModal(setSectionAction(i))}
                />
            </Modal.Header>
            <ModalBody className="hashing-modal__body">
                {alerts &&
                    alerts.map((alert: any, i: number) => {
                        if (alert) {
                            return (
                                <Alert variant={alert.variant} key={i} onClose={() => alertDismiss(i)} dismissible>
                                    {alert.text}
                                </Alert>
                            );
                        }
                        return null;
                    })}
                <h4 className="text-center">MPH Report Upload</h4>
                {renderSwitch}
            </ModalBody>
        </ModalPreset>
    );
}
