import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import formatDate from '../../lib/formatDate';

interface IProps {
    children: React.ReactChild;
    currentTimeframe: WindowSimple;
}
export default function InformedConsentWrapper({ children, currentTimeframe }: IProps) {
    return (
        <Container>
            <Row className="d-flex justify-content-center">
                <p className="mt-4 mb-4 text-center">
                    You should be submitting only new consents for clients served during this data period (
                    {formatDate(currentTimeframe.dataCollectedStart)} - {formatDate(currentTimeframe.dataCollectedEnd)}
                    ). Informed consents for clients only need to be uploaded once.
                </p>
            </Row>
            {children}
            <p className="mt-4 text-center text-nowrap">
                Your files will be uploaded directly to DWD’s storage account.
            </p>
        </Container>
    );
}
