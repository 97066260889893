import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    sorting: boolean;
    ascending: boolean;
}
export function SortIcon({ sorting = false, ascending = true }: IProps) {
    const _icon = useMemo(() => {
        if (sorting) {
            if (ascending) return faSortUp;
            return faSortDown;
        }
        return faSort;
    }, [ascending, sorting]);
    return <FontAwesomeIcon className="ml-1" icon={_icon} />;
}

export function sort(accending: boolean, a: string | number | null | undefined, b: string | number | null | undefined) {
    return accending ? sortAscending(a!, b!) : sortDescending(a!, b!);
}
export function sortAscending(a: string | number, b: string | number) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}
export function sortDescending(a: string | number, b: string | number) {
    if (a > b) {
        return -1;
    }
    if (a < b) {
        return 1;
    }
    return 0;
}
