import { AuthenticationContext, AdalConfig, withAdalLogin, adalFetch } from 'react-adal';
import { config } from './Config';

const adalConfig: AdalConfig = {
    tenant: config.tenantId,
    clientId: config.appId,
    redirectUri: window.location.origin,
    endpoints: {
        api: config.apiUrl,
    },
    cacheLocation: 'sessionStorage' as 'sessionStorage',
    postLogoutRedirectUri: window.location.origin,
};

export const authContext = new AuthenticationContext(adalConfig);
export const logout = () => {
    authContext.logOut();
};

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const withAdalLoginApi = withAdalLogin(authContext, config.tenantId);

export const adalApiFetch = (fetch: any, url: any, options: any) =>
    adalFetch(authContext, adalConfig.clientId, fetch, url, options);
export const adalFetchGraph = (fetch: any, url: any, options: any) =>
    adalFetch(authContext, 'https://graph.microsoft.com', fetch, url, options);
