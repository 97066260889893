import React, { useCallback, useContext, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import AppContext from '../../AppContext';
import Loading from '../common/Loading';
import { BoxedWrapper } from '../BoxedWrapper';
import FilesSelected from '../FilesSelected/FilesSelected';
import TooltipButton from '../TooltipButton';
import { IExtendsInformedConsentModal, informedConsentModalActionCreators } from './informedConsentModalReducer';
import InformedConsentWrapper from './InformedConsentWrapper';
import { SasUri } from '../../api/FileUploadAPI';
import SubmissionsAPI, { SubmissionType } from '../../api/SubmissionsAPI';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import useBlobStorage from './useBlobStorage';

const { resetModalAction, uploadSuccessAction, setSubmissionAction } = informedConsentModalActionCreators;

interface IProps extends IExtendsInformedConsentModal {
    currentTimeframe: WindowSimple;
    sas: SasUri | undefined;
}
export default function InformedConsentUpload({
    modal: { listName, listData, reportName, reportData },
    dispatchModal,
    sas,
    currentTimeframe,
}: IProps) {
    const { user, alertAdd } = useContext(AppContext);

    const submissionsApi = useRef(new SubmissionsAPI());
    const [uploading, setUploading] = useState(false);
    const uploadToStorage = useBlobStorage(sas!);
    const uploadFiles = useCallback(() => {
        if (listData && reportData && sas?.uri) {
            setUploading(true);
            submissionsApi.current
                .createInformedConsentSubmission({
                    submissionType: SubmissionType.INFORMED_CONSENT,
                    userId: user.userData?.userId,
                    windowId: currentTimeframe.windowId,
                })
                .then((result) => {
                    Promise.all([
                        uploadToStorage(result.submissionId, listData),
                        uploadToStorage(result.submissionId, reportData),
                    ])
                        .then((res) => {
                            dispatchModal(setSubmissionAction(result, res[0].blobName, res[1].blobName));
                            alertAdd({
                                variant: 'success',
                                text: 'File successfully uploaded.',
                            });
                            requestAnimationFrame(() => {
                                dispatchModal(uploadSuccessAction());
                            });
                        })
                        .catch(() => {
                            alertAdd({
                                variant: 'danger',
                                text: 'File upload failed. Please retry. If you continue to see this message please contact a site administrator.',
                            });
                            setUploading(false);
                        });
                })
                .catch(() => {
                    alertAdd({
                        variant: 'danger',
                        text: 'File upload failed. Please retry. If you continue to see this message please contact a site administrator.',
                    });
                    setUploading(false);
                });
        }
    }, [alertAdd, currentTimeframe.windowId, dispatchModal, listData, reportData, sas, uploadToStorage, user.userData]);

    if (uploading) return <Loading />;

    return (
        <>
            <InformedConsentWrapper currentTimeframe={currentTimeframe}>
                <BoxedWrapper className="section-1">
                    <div className="py-5">
                        <FilesSelected fileNames={[reportName, listName]} />
                        <TooltipButton
                            onClick={uploadFiles}
                            label="Upload"
                            tooltipText="By uploading a file, you are submitting it to be uploaded directly to DWD’s storage account."
                        />
                    </div>
                </BoxedWrapper>
            </InformedConsentWrapper>
            <div className="text-center">
                <Button onClick={() => dispatchModal(resetModalAction())}>Reselect Files</Button>
            </div>
        </>
    );
}
