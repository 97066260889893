import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface IProps {
    title: string;
    description: string;
    children: React.ReactChild;
}
export default function Template({ title, description, children }: IProps) {
    return (
        <Row className="templates__template mt-4">
            <Col className="justify-content-center d-flex flex-column pl-5">
                <h3 className="mb-4">{title}</h3>
                <p className="mb-0">{description}</p>
            </Col>
            <Col className="justify-content-center align-items-center d-flex flex-column">{children}</Col>
        </Row>
    );
}
