import React, { ContextType } from 'react';
import { Route } from 'react-router-dom';
import Loading from '../common/Loading';

import Windows from './Windows/Windows';
import Organizations from './Organizations/Organizations';
import Users from './Users/Users';
import Admins from './Admins/Admins';
import ArchivedOrganizations from './ArchivedOrganizations/ArchivedOrganizations';

import OrganizationsAPI, { OrganizationSimple } from '../../api/OrganizationsAPI';
import UsersAPI from '../../api/UsersAPI';
import AdminsAPI, { Admin } from '../../api/AdminsAPI';
import WindowsAPI from '../../api/WindowsAPI';
import SubmissionsAPI, { Submission } from '../../api/SubmissionsAPI';
import AppContext, { getDefaultUser } from '../../AppContext';
import NavigationHeader from '../common/NavigationHeader';
import { WindowForm, WindowSimple } from './Windows/windowsLib';
import Submissions from './Submissions/Submissions';
import AlertPreset from '../AlertPreset';

interface AdminRouterState {
    organizations: OrganizationSimple[] | null;
    activeOrganizations: OrganizationSimple[] | null;
    windows: WindowSimple[] | null;
    admins: Admin[] | null;
    submission: Submission[] | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    windowErrorData: any;
}

export default class AdminRouter extends React.Component<{}, AdminRouterState> {
    OrganizationsAPI = new OrganizationsAPI();
    UsersAPI = new UsersAPI();
    AdminsAPI = new AdminsAPI();
    WindowsAPI = new WindowsAPI();
    SubmissionsAPI = new SubmissionsAPI();

    state: AdminRouterState = {
        organizations: null,
        activeOrganizations: null,
        windows: null,
        admins: null,
        submission: null,
        windowErrorData: null,
    };

    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;

    getAllOrganizations = () => {
        this.OrganizationsAPI.getAllOrganizations()
            .then((organizations) => this.setState({ organizations }))
            .catch(this.context.handleError);
    };

    getActiveOrganizations = () => {
        this.OrganizationsAPI.getActiveOrganizations()
            .then((activeOrganizations) => this.setState({ activeOrganizations }))
            .catch(this.context.handleError);
    };

    createOrganization = (newOrgName: string) => {
        this.setState({ organizations: null });
        return this.OrganizationsAPI.createOrganization(newOrgName)
            .then((response) => {
                if (response) {
                    this.getAllOrganizations();
                }
                return false;
            })
            .catch((error) => {
                this.context.handleError(error);
                this.getAllOrganizations();
                return true;
            });
    };

    deleteOrganization = (id: number) => {
        this.setState({ organizations: null });
        this.OrganizationsAPI.deleteOrganization(id)
            .then(() => {
                this.getAllOrganizations();
            })
            .catch(this.context.handleError);
    };

    updateOrganization = (organization: OrganizationSimple) => {
        this.setState({ organizations: null });
        this.OrganizationsAPI.updateOrganization(organization?.organizationId, organization.name)
            .then(() => {
                this.getAllOrganizations();
            })
            .catch(this.context.handleError);
    };

    getAllUsers = () => {
        this.UsersAPI.getAllUsers()
            .then((users) => {
                this.context.setUsers(users);
            })
            .catch(this.context.handleError);
    };

    deleteUser: UsersAPI['deleteUser'] = (email) => {
        return this.UsersAPI.deleteUser(email)
            .then(() => {
                this.getAllUsers();
            })
            .catch(this.context.handleError);
    };

    getAllAdmins = () => {
        this.setState({ admins: null });
        this.AdminsAPI.getAllAdmins()
            .then((admins) => this.setState({ admins }))
            .catch(this.context.handleError);
    };

    createAdmin = (admin: Pick<Required<Admin>, 'email' | 'firstName' | 'lastName'>) => {
        this.setState({ admins: null });
        this.AdminsAPI.createAdmin(admin.firstName!, admin.lastName!, admin.email!)
            .then(() => {
                this.getAllAdmins();
            })
            .catch((error) => {
                this.context.handleError(error);
                this.getAllAdmins();
            });
    };

    deleteAdmin = (email: string) => {
        this.setState({ admins: null });
        this.AdminsAPI.deleteAdmin(email)
            .then(() => {
                this.getAllAdmins();
            })
            .catch(this.context.handleError);
    };

    updateAdmin = (admin: Pick<Required<Admin>, 'email' | 'firstName' | 'lastName'>) => {
        this.setState({ admins: null });
        this.AdminsAPI.updateAdmin(admin.email!, admin.firstName!, admin.lastName!)
            .then(() => {
                this.getAllAdmins();
            })
            .catch(this.context.handleError);
    };

    getAllWindows = () => {
        this.WindowsAPI.getAllWindows()
            .then((windows) => this.setState({ windows }))
            .catch(this.context.handleError);
    };

    createWindow = (newWindow: WindowForm) => {
        this.setState({ windows: null });
        return this.WindowsAPI.createWindow(newWindow)
            .then((response) => {
                if (response) {
                    this.getAllWindows();
                }

                return true;
            })
            .catch((error) => {
                this.setState({
                    windowErrorData: error,
                });
                // this.context.handleError(error);
                this.getAllWindows();

                return false;
            });
    };

    createWindowOrg = (editWindow: WindowForm) => {
        return this.WindowsAPI.createWindowOrg(editWindow)
            .then((response) => {
                if (response) {
                    this.getAllWindows();
                }

                return true;
            })
            .catch((error) => {
                this.context.handleError(error);
                this.getAllWindows();
                return false;
            });
    };

    deleteWindow = (windowId: NonNullable<WindowSimple['windowId']>) => {
        this.WindowsAPI.deleteWindow(windowId).catch(this.context.handleError).finally(this.getAllWindows);
    };

    deleteWindowOrg = (editWindow: WindowForm) => {
        this.WindowsAPI.deleteWindowOrg(editWindow).catch(this.context.handleError).finally(this.getAllWindows);
    };

    updateWindow = (editWindow: WindowForm) => {
        return this.WindowsAPI.updateWindow(editWindow)
            .then(() => {
                this.setState({
                    windowErrorData: null,
                });
                return true;
            })
            .catch((error) => {
                this.setState({
                    windowErrorData: error,
                });
                return false;
            })
            .finally(this.getAllWindows);
    };

    resetWindowErrorData = () => {
        this.setState({
            windowErrorData: null,
        });
    };

    getAllSubmissions = () => {
        this.SubmissionsAPI.getAllSubmissions()
            .then((submission) => this.setState({ submission }))
            .catch(this.context.handleError);
    };

    archiveWindow = (windowId: WindowForm['windowId']) => {
        this.WindowsAPI.archiveWindow(windowId).catch(this.context.handleError).finally(this.getAllWindows);
    };

    emailWindowOrganizations = (window: WindowForm, emailBody: string) => {
        return this.WindowsAPI.emailWindowOrganizations(window, emailBody)
            .then(() => true)
            .catch(() => false);
    };

    sendNotification = (windowId: number) => {
        this.WindowsAPI.sendNotification(windowId)
            .then(() =>
                this.context.alertAdd({
                    variant: 'success',
                    text: 'Notification sent!',
                }),
            )
            .catch((error) => this.context.handleError(error))
            .finally(this.getAllWindows);
    };

    render() {
        return (
            <div>
                <Route path="/admin" component={NavigationHeader} />
                {this.context.alerts &&
                    this.context.alerts.map((alert: any, i: number) => {
                        return (
                            <AlertPreset
                                key={i}
                                alert={alert}
                                onClose={() => this.context.alertDismiss(i)}
                                dismissible
                            />
                        );
                    })}

                <React.Suspense fallback={<Loading />}>
                    <Route
                        exact
                        path="/admin"
                        render={() => (
                            <Submissions
                                submissions={this.state.submission}
                                handleError={this.context.handleError}
                                getAllSubmissions={this.getAllSubmissions}
                            />
                        )}
                    />
                    <Route
                        path="/admin/windows"
                        render={() => (
                            <Windows
                                windows={this.state.windows}
                                organizations={this.state.organizations}
                                activeOrganizations={this.state.activeOrganizations}
                                windowErrorData={this.state.windowErrorData}
                                getAllWindows={this.getAllWindows}
                                // getAllOrganizations={this.getAllOrganizations}
                                getActiveOrganizations={this.getActiveOrganizations}
                                createWindow={this.createWindow}
                                createWindowOrg={this.createWindowOrg}
                                deleteWindow={this.deleteWindow}
                                deleteWindowOrg={this.deleteWindowOrg}
                                updateWindow={this.updateWindow}
                                archiveWindow={this.archiveWindow}
                                emailWindowOrganizations={this.emailWindowOrganizations}
                                sendNotification={this.sendNotification}
                                resetWindowErrorData={this.resetWindowErrorData}
                            />
                        )}
                    />
                    <Route
                        path="/admin/organizations"
                        render={() => (
                            <Organizations
                                organizations={this.state.organizations}
                                getAllOrganizations={this.getAllOrganizations}
                                createOrganization={this.createOrganization}
                                deleteOrganization={this.deleteOrganization}
                                updateOrganization={this.updateOrganization}
                            />
                        )}
                    />
                    <Route
                        path="/admin/archivedorganizations"
                        render={() => (
                            <ArchivedOrganizations
                                organizations={this.state.organizations}
                                handleError={this.context.handleError}
                                getAllOrganizations={this.getAllOrganizations}
                                createOrganization={this.createOrganization}
                                deleteOrganization={this.deleteOrganization}
                                updateOrganization={this.updateOrganization}
                            />
                        )}
                    />
                    <Route
                        path="/admin/users"
                        render={() => (
                            <Users
                                organizations={this.state.organizations}
                                getAllUsers={this.getAllUsers}
                                getAllOrganizations={this.getAllOrganizations}
                                deleteUser={this.deleteUser}
                            />
                        )}
                    />
                    <Route
                        path="/admin/admins"
                        render={() => (
                            <Admins
                                admins={this.state.admins}
                                getAllAdmins={this.getAllAdmins}
                                createAdmin={this.createAdmin}
                                deleteAdmin={this.deleteAdmin}
                                updateAdmin={this.updateAdmin}
                            />
                        )}
                    />
                </React.Suspense>
            </div>
        );
    }
}
