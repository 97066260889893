import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authContext, getToken, logout } from './adalConfig';
import AdminsAPI from './api/AdminsAPI';
import UsersAPI, { UserSimple } from './api/UsersAPI';
import { getUserDetails, getUserGroupPermission } from './assets/js/GraphService';
import { config } from './Config';

export interface Alert {
    variant: string;
    text: string;
}

export interface User {
    displayName: string;
    email: string;
    isAdmin: boolean;
    userData: UserSimple | null;
}
const defaultUser = {
    displayName: '',
    email: '',
    isAdmin: false,
    userData: null,
};
export function getDefaultUser() {
    return Object.assign({}, defaultUser);
}

export interface IAppContext {
    user: User;
    users: UserSimple[] | null;
    setUsers: React.Dispatch<React.SetStateAction<UserSimple[] | null>>;
    alerts: Alert[];
    setUser: (user: User) => void;
    alertAdd(newAlert: Alert): void;
    handleError(e: Error): void;
    alertDismiss(alertIndex: number): void;
    resetAlerts: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
}
const AppContext = React.createContext<IAppContext>({
    user: {
        displayName: '',
        email: '',
        isAdmin: false,
        userData: null,
    },
    users: null,
    setUsers: () => void 0,
    setUser: () => void 0,
    alerts: [],
    alertAdd: () => void 0,
    handleError: () => void 0,
    alertDismiss: () => void 0,
    resetAlerts: () => void 0,
    error: null,
});
export default AppContext;

const usersAPI = new UsersAPI();
const adminsAPI = new AdminsAPI();

interface IProps {
    children: React.ReactChild;
}
export function AppContextProvider({ children }: IProps) {
    const [alerts, setAlerts] = useState<Alert[]>([]);

    const alertAdd = useCallback(
        (newAlert: Alert) => {
            let addAlert = true;

            alerts.map((alert) => {
                if (alert.text === newAlert.text) {
                    addAlert = false;
                }

                return addAlert;
            });

            if (addAlert) {
                setAlerts((_) => _.concat(newAlert));
            }
            window.scrollTo(0, 0);
        },
        [alerts],
    );

    const handleError = useCallback(
        (error: Error) => {
            if (process.env.NODE_ENV !== 'production') {
                console.warn(error);
            }
            alertAdd({
                variant: 'danger',
                text: error.message,
            });
        },
        [alertAdd],
    );

    const alertDismiss = useCallback((id: number) => {
        setAlerts((_) => _.filter((_alert, i) => i !== id));
    }, []);

    const resetAlerts = useCallback(() => {
        setAlerts([]);
    }, []);

    const [user, setUser] = useState<User>(getDefaultUser());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>();

    const history = useHistory();
    const getUserProfile = useCallback(() => {
        const newUser = { ...user };
        try {
            // Get the access token silently
            // If the cache contains a non-expired token, this function
            // will just return the cached token. Otherwise, it will
            // make a request to the Azure OAuth endpoint to get a token

            var adalToken = getToken();

            if (adalToken) {
                authContext.acquireToken('https://graph.microsoft.com', (error, token) => {
                    return getUserDetails(token).then((response) => {
                        // * copy user to apply multiple setUsers
                        setUser(
                            Object.assign(newUser, {
                                displayName: response.displayName,
                                email: response.userPrincipalName,
                            }),
                        );

                        return getUserGroupPermission(token, config.adminGroupId)
                            .then((response) => {
                                if (response.id === config.adminGroupId) {
                                    adminsAPI
                                        .getAdmin(newUser.email)
                                        .then(() => {
                                            setUser(
                                                Object.assign(newUser, {
                                                    isAdmin: true,
                                                }),
                                            );
                                            history.push('/admin');
                                        })
                                        .catch(() => {
                                            logout();
                                        });
                                }
                            })
                            .catch(() => {
                                return getUserGroupPermission(token, config.userGroupId)
                                    .then((response) => {
                                        if (response.id !== typeof 'undefined') {
                                            if (response.id === config.userGroupId) {
                                                usersAPI
                                                    .getUser(newUser.email)
                                                    .then((result) => {
                                                        setUser(
                                                            Object.assign(newUser, {
                                                                isAdmin: false,
                                                                userData: result,
                                                            }),
                                                        );
                                                        history.push('/user');
                                                    })
                                                    .catch(() => {
                                                        logout();
                                                    });
                                            }
                                        }
                                    })
                                    .catch(() => {
                                        logout();
                                    });
                            });
                    });
                });
            }
        } catch (err) {
            var error = {};
            if (typeof err === 'string') {
                var errParts = err.split('|');
                error =
                    errParts.length > 1
                        ? {
                              debug: errParts[0],
                              message: errParts[1],
                          }
                        : {
                              message: err,
                          };
            } else {
                error = {
                    debug: JSON.stringify(err),
                    message: (err as Error).message,
                };
            }

            setUser(
                Object.assign(newUser, {
                    isAdmin: false,
                }),
            );
            setError(error);
        }
    }, [history, user]);

    useEffect(function mountApp() {
        getUserProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [users, setUsers] = useState<UserSimple[] | null>(null);

    return (
        <AppContext.Provider
            value={{
                user,
                users,
                setUsers,
                alerts,
                alertAdd,
                handleError,
                setUser,
                alertDismiss,
                resetAlerts,
                error,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
