import 'dotenv/config';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Login from './components/Login/LoginPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import history from './history';

import { runWithAdal } from 'react-adal';
import { authContext, getToken } from './adalConfig';

const DO_NOT_LOGIN = false;

const authenticate = () => {
    runWithAdal(
        authContext,
        () => {
            history.push('/loading');
            require('./indexApp');
        },
        DO_NOT_LOGIN,
    );
};

if (window.location.href.includes('#id_token=') || getToken()) {
    runWithAdal(
        authContext,
        () => {
            history.push('/loading');
            require('./indexApp');
        },
        DO_NOT_LOGIN,
    );
} else {
    ReactDOM.render(
        <Router>
            <Route path="/" render={() => <Login authenticate={authenticate} />} />
        </Router>,
        document.getElementById('root'),
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
