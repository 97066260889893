import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AppContext from '../../../AppContext';
import { components } from '../../../types/openApi';
import UpsertUserModal from './UpsertUserModal';
import UsersAPI from '../../../api/UsersAPI';
import DeleteUserModal from './DeleteUserModal';
import UsersTable from './UsersTable';

interface IProps {
    getAllUsers(): void;
    getAllOrganizations(): void;
    deleteUser: UsersAPI['deleteUser'];
    organizations: components['schemas']['OrganizationSimple'][] | null;
}

export default function Users({ deleteUser, getAllUsers, organizations, getAllOrganizations }: IProps) {
    const { users, alertDismiss } = useContext(AppContext);
    const [selectedUser, setSelectedUser] = useState<components['schemas']['UserSimple'] | null>();
    const [deleteUserEmail, setDeleteUserEmail] = useState<NonNullable<components['schemas']['UserSimple']['email']>>();

    useEffect(() => {
        !users && getAllUsers();
        !organizations && getAllOrganizations();
    });

    return (
        <div>
            <Container>
                <Row className="mt-2">
                    <Col md={4}>
                        <h2>Users</h2>
                    </Col>
                    <Col className="text-right" md={8}>
                        <Button onClick={() => setSelectedUser(null)}>Add New User</Button>
                    </Col>
                </Row>
                <UsersTable onEdit={setSelectedUser} onDelete={setDeleteUserEmail} />

                <UpsertUserModal
                    user={selectedUser}
                    onUpsertUser={() => getAllUsers()}
                    onHide={() => {
                        setSelectedUser(undefined);
                        alertDismiss(0);
                    }}
                    organizations={organizations}
                />

                <DeleteUserModal
                    show={Boolean(deleteUserEmail)}
                    onDelete={() => {
                        deleteUser(deleteUserEmail ?? '');
                        setDeleteUserEmail(undefined);
                    }}
                />
            </Container>
        </div>
    );
}
