import APILayer from './APILayer';
import { components } from '../types/openApi';

export interface ValidatedData {
    cbO_ID: string;
    cuid: string;
    family_ID: string;
    fn: string;
    ln: string;
    dob: string;
    zip: string;
    gender: string;
    race: string;
}

export interface ValidatedHash {
    data: ValidatedData[] | null;
    rowsSubmitted: number;
    hash: string;
}

export type ValidatedHashError = { rowNumbers: number }[] | { message: string };
export interface FileUpload {
    cbO_ID: string | null;
    cuid: string | null;
    family_ID: string | null;
    fn: string | null;
    ln: string | null;
    dob: string;
    zip: string | null;
    gender: string | null;
    race: string | null;
}

export interface ValidData {
    data: FileUpload[] | null;
    rowsSubmitted: number;
    hash: string | null;
}

export interface InvalidFileData {
    column: string | null;
    dataType: string | null;
    rowNumbers: number[] | null;
}

export interface ValidationResponse {
    fileIsValid: boolean;
    validData: ValidData;
    validationErrors: InvalidFileData[] | null;
}

export type SasUri = components['schemas']['SasUri'];

export default class FileUploadAPI {
    APILayer = new APILayer();

    async postFileUpload(file: File): Promise<ValidationResponse> {
        let data = new FormData();

        data.append('File', file);

        return this.APILayer.post(`/api/FileUpload/ValidateFile`, data, true).then(async (response) => {
            if (response.status === 500) {
                throw new Error('File upload failed. Please retry.');
            }

            if (response.status === 400) {
                throw new Error('File is not valid. Please retry.');
            }

            return response.json();
        });
    }

    async submitFile(file: File, windowId: number, rowsValidated: number) {
        let data = new FormData();

        data.append('file', file);
        data.append('windowId', String(windowId));
        data.append('rowsValidated', String(rowsValidated));

        return this.APILayer.post('/api/FileUpload/Submit', data, true).then((response) => {
            if (response.status !== 200) {
                return false;
            }

            return response.json();
        });
    }

    async getSasToken(): Promise<SasUri> {
        return this.APILayer.get('/api/FileUpload/SasToken').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to prepare Informed Consent for upload');
            }
            return response.json();
        });
    }

    // async putStorage(sas: SasUri, file: File): Promise<any> {
    // 	const body = toBase64(file)
    // 	let headers = new Headers();
    // 	headers.set('Content-Type', `${file.type}; charset=UTF-8`);
    // 	headers.set('Content-Length', '0');
    // 	headers.set('x-ms-version', "2021-06-08");
    // 	headers.set('x-ms-date', sas.createdOn!);
    // 	headers.set('x-ms-blob-content-type', file.type);
    // 	headers.set('x-ms-blob-type', 'BlockBlob');
    // 	return fetch(sas.uri!, {
    // 		method: 'PUT',
    // 		headers,
    // 		body: JSON.stringify(body)
    // 	}).then(response => response)
    // }
}
